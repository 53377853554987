import React, {Component} from 'react';
import PropTypes from 'prop-types';


export default class CommunicationComponent extends Component {


    constructor(props) {
      super(props);
      if (typeof window.websiteConfListener === "undefined") {
        window.websiteConfListener = {};
      }
    }

    fireEvent(eventName, data) {
      if (typeof window.websiteConfListener[eventName] === "undefined") {
        window.websiteConfListener[eventName] = [];
      }

      const listeners = window.websiteConfListener[eventName].filter((d) => d.eventName === eventName || d.eventName == null);

      listeners.map((l) => l.cb(eventName, data));

    }

    registerEventFor(eventName, callback) {
        if (typeof window.websiteConfListener[eventName] === "undefined") {
          window.websiteConfListener[eventName] = [];
        }

        const alreadyRegistered = window.websiteConfListener[eventName].filter((d) => d.classInstance === this).length > 0;
        if (!alreadyRegistered) {
          window.websiteConfListener[eventName].push({
            classInstance: this,
            eventName: eventName,
            cb: callback
          });
        }


    }


}
