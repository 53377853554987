import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';
import Rating from "react-rating";
import Handlebars from "handlebars";



class QuestionHtml extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getValueAsInt() {
        try {
            return parseInt(this.getValue());
        }
        catch(e) {
            return 0;
        }
    }

    getVorauswahlasInt() {
        try {
            return parseInt(this.getVorauswahl());
        }
        catch(e) {
            return 0;
        }
    }

    componentDidMount() {
        if (this.question()["script"]) {
            window.eval(this.question()["script"]);
        }
    }

    getParsedHtml() {
        const data = this.question()["html"];
        try {
            var template = Handlebars.compile(data ? data : '');
            const d = template(Object.assign({}, this.qCtrl().formular.state.website,{
                zoho: this.qCtrl().getZohoCustomerData(),

            }));
            return d;
        }
        catch(e) {

        }
        return data;
    }

    render() {
        return <Container fluid={true} className={"ranking-container "+(this.hasError() ? "error" : "")}>
            <Row>
                <Col className={"p-1"} md={12}>
                    <span dangerouslySetInnerHTML={{ __html: this.getParsedHtml() }}></span>

                </Col>
            </Row>
        </Container>;
    }
};

QuestionHtml.propTypes = {

};


export default QuestionHtml;
