import React, {Component} from 'react';

export default class AirtableHelper {

    static createElementByHtmlRTF(text, className="subtext") {

        return <span className={className} dangerouslySetInnerHTML={{ __html: text }}></span>;
    }

}
