



export function sendMessageToPreview(message) {
  if (typeof window !== "undefined") {
    if (document.getElementById("website-preview-frame")) {
      // @ts-ignore
      document.getElementById("website-preview-frame").contentWindow
        .postMessage({universal: message}, "*");
    }
  }
}


export function generateRandomObjectId() {
  var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
    return (Math.random() * 16 | 0).toString(16);
  }).toLowerCase();
}

export function generateRandomId() {
  // generate a random objectid (12 bytes)
  var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      }
      )
      .toLowerCase()
  );
}

export function expandConditionForCurrentQuestion(condition, replaceIdentifier, question, list) {
  condition = JSON.parse(JSON.stringify(condition));
  condition.id = generateRandomId();
  if (condition.jsonQuery) {
    condition.jsonQuery = expandConditionForCurrentQuestion(condition.jsonQuery, replaceIdentifier, question, list);
  }
  else if (typeof condition.type !== "undefined") {
    if (condition.children1) {
      for (const e of condition.children1) {
        if (e.type === "rule") {
          if (e.properties.field) {
            if (list.find((f) => f.id === e.properties.field)) {
              e.properties.field = replaceIdentifier.replace("{{id}}", e.properties.field);
            }
          }
        }
      }
    }
  }

  return condition;
}



function getRelativePos(elm, container){
  var pPos = container.getBoundingClientRect(), // parent pos
    cPos = elm.getBoundingClientRect(), // target pos
    pos = {};

  pos.top    = container.scrollTop + cPos.top,
    pos.right  = cPos.right ,
    pos.bottom = cPos.bottom ,
    pos.left   = cPos.left  ;

  return pos;
}


export function scrollIfNeeded(element, container) {
  const pos = getRelativePos(element, container);
  if (pos.top < container.scrollTop) {
    container.scrollTo({ top: pos.top-130, behavior: 'smooth' });
  }
}



export function sendFullWebsiteUpdate(website, qCtrl) {
  return new Promise( (resolve, reject) => {
    if (typeof window !== "undefined") {
      if (document.getElementById("website-preview-frame")) {

        const targetUrl = qCtrl.formular.state.websitePreview.previewURL;
        if (targetUrl != null) {
          if (targetUrl != window.websiteconfig_targetUrl) {
            qCtrl.getPageDataByPath(targetUrl)
              .then((pageData) => {
                if (pageData && pageData.data) {
                  website.page = pageData.data.data;
                }
                website = qCtrl.updateWebsiteObjectBeforePreview(website);
                // @ts-ignore
                document.getElementById("website-preview-frame").contentWindow
                  .postMessage({full_update: website, path: targetUrl}, "*");
                window.websiteconfig_targetUrl = targetUrl;
              });
          }
          else {
            website = qCtrl.updateWebsiteObjectBeforePreview(website);
            // @ts-ignore
            document.getElementById("website-preview-frame").contentWindow
              .postMessage({full_update: website, path: window.websiteconfig_targetUrl}, "*");
          }
        }
        else {
          website = qCtrl.updateWebsiteObjectBeforePreview(website);
          // @ts-ignore
          document.getElementById("website-preview-frame").contentWindow
            .postMessage({full_update: website, path: window.websiteconfig_targetUrl}, "*");
        }


      }
    }
    return resolve();
  });

}

