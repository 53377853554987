export const API_URL = '';
export const API_BASE = '/api/configurators/byName';
export const UPLOAD_API = '/api/configurators/upload';
export const FINISH_CONFIGURATION_URL = '/api/configurators/finish';
export const FINISH_CONFIGURATION_URL_HQ = '/api/configurators/finishHQ';
export const WEBCONFIG_HOME_URL = '';
export const IMAGE_URL = '';
export const DB_LOGIN_API = 'http://localhost:9991';
export const DB_API = '/api/configurators/';
export const DB_URL = '';
export const DOWNLOAD_API = 'http://localhost:9991';
export const ZOHO_API =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/finish/';
export const ZOHO_EDIT_API =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/editUpdate/';
export const ZOHO_API_SAVE_CATEGORY =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/saveCategory/';
export const ZOHO_EDIT_API_CATEGORY =
  'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/saveCategory/';
export const WEBCONFIG_TRADE_MODE = 'SHK';
export const SHOW_WEBSITE_BUY = true;
export const SHOW_PRICE_TABLE = false;
