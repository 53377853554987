import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';
import Rating from "react-rating";



class QuestionRanking extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getValueAsInt() {
        try {
            return parseInt(this.getValue());
        }
        catch(e) {
            return 0;
        }
    }

    getVorauswahlasInt() {
        try {
            return parseInt(this.getVorauswahl());
        }
        catch(e) {
            return 0;
        }
    }

    render() {
        return <Container fluid={true} className={"ranking-container "+(this.hasError() ? "error" : "")}>
            <Row>
                <Col className={"p-1"} md={12}>

                    <Rating initialRating={this.getValueAsInt()}
                            readonly={this.isFinished() || this.isDisabled()}
                            emptySymbol={<img src={"images/star-empty.png"} className="icon" />}
                            fullSymbol={<img src={"images/star-full.png"} className="icon" />}
                            onChange={(e) => this.setValue(e)} />
                    {this.renderErrors()}
                </Col>
            </Row>
        </Container>;
    }
};

QuestionRanking.propTypes = {

};


export default QuestionRanking;
