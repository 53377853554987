import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";

class QuestionLink extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getTextValue() {
        const txt = this.getValue();
        return txt;
    }

    getGeneratedLink() {
        return this.qCtrl().replaceVariables(this.placeholder());
    }

    render() {
        return <Container fluid={true} className={"text-container "+(this.hasError() ? "error" : "")}>
            <Row>
                <Col className={"p-1"} md={12}>
                    <a target={"_blank"} type={"text"} href={this.getGeneratedLink()} name={"link"} >{this.getGeneratedLink()}</a>
                    {this.renderErrors()}
                </Col>
            </Row>
        </Container>;
    }
};

QuestionLink.propTypes = {

};


export default QuestionLink;
