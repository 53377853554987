import Universal20Service from "./Universal20Service";
import QuestionController from "../controller/QuestionController";
import _ from "lodash";
import { API_BASE, API_URL } from "../config";

export default class FormularConfigurationService {

    constructor(controller = null) {
        this.api = new Universal20Service();
        this.setController(controller);
        this.categories = [];
        this.modelMode = "u20";
        this.schema = null;
        this.error = null;
        this.data = {
            answers: {},
            summaryConfig: {}
        };
        this.options = {};
        this.flatQuestions = {};
    }

    static buildHierarchicalQuestions(records) {
        const acategories = {};
        for (const r of records) {
            if (typeof r.Category === "undefined" || r.Category === "") {
                continue;
            }
            if (typeof acategories[r.Category] === "undefined"){
                acategories[r.Category] = {
                    subcategories: [{
                        id: r.Subcategory,
                        name: r["Subcategory Name"],
                        elements: [r]
                    }],
                    id: r.Category,
                    name: r.Kategoriename
                };
            }
            else {
                const cat = acategories[r.Category];
                const found = cat.subcategories.findIndex((d) => d.id == r.Subcategory);
                if (found >= 0) {
                    cat.subcategories[found].elements.push(r);
                }
                else {
                    cat.subcategories.push({
                        id: r.Subcategory,
                        name: r["Subcategory Name"],
                        elements: [r]
                    });
                }
            }
            if (typeof r.OriginalVorauswahl === "undefined") {
                r.OriginalVorauswahl = (r.Vorauswahl) ? JSON.parse(JSON.stringify(r.Vorauswahl)) : [];
            }

        }
        let elements =  Object.values(acategories);
        for (let e of elements) {
            try {
                e.subcategories = _.sortBy( e.subcategories, 'id' );
            }
            catch(ignoreError) {

            }

        }
        return elements;
    }

    setSchema(schema) {
        this.schema = schema;
    }

    setElements(records) {

        let sc_index = 0;
        this.data = records.data;
        this.modelMode = records.data.modelMode;
        const sorted = records.questions.sort(QuestionController.sortQuestions);
        const acategories = FormularConfigurationService.buildHierarchicalQuestions(sorted);
        this.flatQuestions = {};
        this.data.configuration = records.configuration;
        for (const r of records.questions) {
            if (typeof r.Category !== "undefined" && typeof r.Subcategory !== "undefined" &&
                typeof r.Kategoriename !== "undefined" && r.Category != null && r.Subcategory != null) {
                this.flatQuestions[r.id] = r;
                this.data.answers[r.id] = {
                    value: r.value,
                    isVorauswahl: r.value ? false : true,
                    comment: r.comment
                };

                if (r.targetField) {
                    this.data.answers[r.id]["field"] = {
                        name: r.targetField.id,
                        id: r.targetField.id,
                        type: r.targetField.type
                    };
                }
            }
            else {
                console.info("Ignoring question row: ", r);
            }
        }

        this.categories = acategories;
    }

    loadConfigAndStore(devMode, infoCallbackMsg) {
        const _this = this;
        return new Promise((resolve, reject)  => {
            _this.api.loadDeepSchemaModel()
                .then((data) => {
                   _this.setSchema(data);
                });
             _this.api.loadConfigurationSide(devMode, infoCallbackMsg)
                 .then((records) => {

                     _this.setElements(records);
                     return resolve();
                 })
                   .catch((err) => {
                       if (err.response) {
                           _this.setError(err.response.data.error);
                           reject(new Error(err.response.data.error));
                       }
                       reject(new Error("Bitte lade die Seite neu, es scheint ein problem zu geben."));
                        setTimeout(() => {
                            if (window.confirm("Es ist ein Fehler aufgetreten, bitte lade die Seite neu.")) {
                                window.location.reload();
                            }
                        }, 2000);
                   });
        });
    }

    setError(msg) {
        this.error = msg;
    }

    hasError() {
        return this.error;
    }

    getOptions() {
        return this.options;
    }

    getFlatQuestionsSorted() {
        return Array.from(Object.values(this.flatQuestions)).sort((a,b) => QuestionController.sortQuestions(a,b));
    }

    getAllComments() {
        return this.data.allComments;
    }

    isFinished() {
        return this.data.finished;
    }

    getSummaryConfig() {
        return this.data.summaryConfig;
    }

    getWebsiteData() {
        this.data.website.page = this.data.website.startpage;
        return this.data.website;
    }

    getTranslations() {
        return this.data.translation;
    }

    getSchema() {
        return this.schema;
    }

    getAnswers() {
        return this.data.answers;
    }

    getFlatQuestions() {
        return this.flatQuestions;
    }

    getConfiguration() {
        return this.data.configuration;
    }

    getCustomerName() {
        return this.data.name;
    }

    getCategories() {
        return this.categories;
    }

    createSemaphore() {

        const config_data_id = this.api.getCurrentHashParameter("cid");
        const url = API_URL;
        this.api.post(url+"/api/semaphores/lock/read/"+config_data_id+"/30", {
               source: "webconfig",
            })
          .then(() => {

          })
          .catch((e) => {
              // ignore
          });
    }

    stopSemaphoreLocking() {
        clearInterval(this.semaphoreInterval);
    }

    startSemaphoreLocking() {
        this.createSemaphore();
        this.semaphoreInterval = setInterval(() => {
            this.createSemaphore();
        }, 31000);
    }

    loadElementDeep(values) {
        const config_data_id = this.api.getCurrentHashParameter("cid");
        const url = API_URL;
        return this.api.post(url+"/api/configurators/"+config_data_id+"/loadDeep", {
                element: values,
                website: config_data_id
            })
          .then((d) => {
                return d.data;
          })
          .catch((e) => {
              return null;
          });
    }

    setController(cb) {
        this.api.setController(cb);
    }

    getModelMode() {
        return this.modelMode;
    }
}
