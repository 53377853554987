import React, {Component} from 'react';
import Container from "reactstrap/lib/Container";
import Button from "reactstrap/lib/Button";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

export default class ResultsTodos extends Component {

    constructor(props) {
        super(props);

    }

    qCtrl() {
        return this.props.qctrl;
    }

    getResults() {
        return this.qCtrl().getAllResults();
    }

    renderResults() {
        const results = this.getResults();

        const answerReults = [];
        results.answers.map((d) => {
            const q = this.qCtrl().findQuestionById(d.key);
            if (d.todos && d.todos.length > 0) {
                d.todos.map((t) => {
                    try {
                        const todo = this.qCtrl().getCraftsmenTodosDetails(t);
                        answerReults.push(<Row>
                            <Col md={6}>
                                {q.Text}
                            </Col>
                            <Col md={6}>
                                {todo.Text}
                            </Col>
                        </Row>);
                    }
                    catch (e) {}
                });
            }
        });

        return <Row>
                <Col md={12}>
                    {answerReults.length > 0 && <h2>Notwendige Maßnahmen</h2>}
                    {answerReults}
                </Col>
            </Row>;
    }

    render() {
        return <Container fluid={true}>
            <Row className={"p-4"}>
                <Col md={12}>
                    {this.renderResults()}
                </Col>
            </Row>
        </Container>;
    }
}
