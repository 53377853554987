import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from "reactstrap";
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';
import Accordion from "../Accordion";
import { geti } from "@agenturid/u20tools";
import { expandConditionForCurrentQuestion, generateRandomObjectId } from "../../helper/helper";

class QuestionRepeated extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {
      accordionTabs: {},
      elements: []
    };
  }

  getTextValue() {
    let txt = this.getValue();
    if (txt == null && this.hasVorauswahl()) {
      txt = this.getVorauswahl();
      this.setValue(txt);
    }
    return txt;
  }

  buildQuestionForBasicQuestion(repeatedQuestion, repeatedElement, value, q, i, list, additionalData) {

    const metaInfo = {};
    let id = this.question().id+"_"+q.id;
    let replaceIdentifier = this.question().id+"_{{id}}";
    if (value && value.id) {
      id = this.question().id+"_"+value.id+"_"+q.id+"_"+repeatedQuestion.id;
      replaceIdentifier = this.question().id+"_"+value.id+"_{{id}}_"+repeatedQuestion.id;
    }
    else if (value && value._id) {
      id = this.question().id+"_"+value._id+"_"+q.id+"_"+repeatedQuestion.id;
      replaceIdentifier = this.question().id+"_"+value._id+"_{{id}}_"+repeatedQuestion.id;
    }

    let v = value;
    if (q.type !== "parameter") {
      if (q.targetField) {
        v = geti(value, q.targetField.id);
      }
    }

    if (q.type === "parameter") {
      if (q.parameterObject) {
        const pobject = geti(v, q.parameterObject.id);
        if (pobject) {
          if (pobject.parameters) {
            metaInfo.parameters = pobject.parameters;
          }
        }
      }
    }

    if (q.type === "condition") {
      const qclist = [];

      for (const qc of q.questions) {
        const condition = expandConditionForCurrentQuestion(q.condition, replaceIdentifier, q , list);
        const qcq = this.buildQuestionForBasicQuestion(repeatedQuestion, repeatedElement, value,
          qc, i, list, {
            condition: condition,
            Bedingung: condition
          });
        if (Array.isArray(qcq)) {
          for (const e of qcq) {
            qclist.push(e);
          }
        }
        else {
          qclist.push(qcq);
        }
      }

      return qclist;
    }

    const question = Object.assign({}, q, {
      id: id,
      parentQuestion: repeatedQuestion,
      parentElement: value,
      metaInfo: metaInfo
    }, additionalData);

    return {
      question: Object.assign({}, q, {
        commentChildQuestionId: q.id,
        dynamicTargetField: this.generateTargetField(repeatedQuestion, repeatedElement, value, q),
        parentQuestion: repeatedQuestion,
        parentElement: value,
        question: question,
        value: v,
        Text: q.text,
        Datentyp: q.type,
        id: id,
        Category: this.question().Category,
        Subcategory: this.question().Subcategory
      }, additionalData),
      answer: {
        id: id,
        question: question,
        value: v
      }
    };
  }


  buildQuestionForBasicQuestions(value, list, repeatedQuestion, repeatedElement) {
    const d = {
      questions: [],
      answers: []
    };
    list.map((q, index) => {
        const f = this.buildQuestionForBasicQuestion(repeatedQuestion, repeatedElement, value, q, index, list);
        if (Array.isArray(f)) {
          for (const e of f) {
            d.questions.push(e.question);
            d.answers.push(e.answer);
          }
        }
        else {
          d.questions.push(f.question);
          d.answers.push(f.answer);
        }

    });

    return d;
  }

  buildAccordionTabs() {

    if (this.question().repeatedQuestion) {
      const q = this.qCtrl().getQuestionById(this.question().repeatedQuestion.id);
      const v = this.qCtrl().getValueForQuestion(q);
      let vWithLabels = this.qCtrl().getOptionByName(q, v);

      if (vWithLabels) {
        if (!Array.isArray(vWithLabels)) {
          vWithLabels = [vWithLabels];
        }
        const answers = [];
        const accordionTabs = {};
        vWithLabels.map((d) => {
          if (d && d.id && d.text) {
            d = Object.assign({}, d, {
                "name": d.text,
                _id: d.id
              });
          }
          if (d && d.name) {
            const questionAndAnswers = this.buildQuestionForBasicQuestions(d,
              this.question().questions, q, null);

            accordionTabs[d.name] = {
              name: d.name,
              element: d,
              parentQuestion: this.question(),
              questions: questionAndAnswers.questions
            };
            answers.push(...questionAndAnswers.answers);
          }
        });

        this.qCtrl().resetMultipleAnswers(answers);

        this.setState({elements: vWithLabels, accordionTabs: accordionTabs});

      }
    }
    else if (this.question().repeatedElement) {
      let v = this.qCtrl().getValueForField(this.question().repeatedElement);
      //const vWithLabels = this.qCtrl().getOptionByName(q, v);
      const q = {
        id: this.question().id
      };

      if (v) {

        if(!Array.isArray(v)) {
          v = [v];
        }
        v = v.map((e) => {
                      if (typeof e === "string") {
                        return {
                          _id: e
                        };
                      }
                      return e;
                    });
        const answers = [];
        const accordionTabs = {};
        v.map((d) => {
          if (d && d._id) {
            const questionAndAnswers = this.buildQuestionForBasicQuestions(d,
              this.question().questions, q, this.question().repeatedElement);

            accordionTabs[d._id] = {
              name: d.name ? d.name : d.text ? d.text : d._id,
              element: d,
              parentQuestion: this.question(),
              questions: questionAndAnswers.questions,
              creatable: this.question().creatable
            };
            answers.push(...questionAndAnswers.answers);
          }
        });

        this.qCtrl().resetMultipleAnswers(answers);

        this.setState({elements: v, accordionTabs: accordionTabs});

      }
    }

  }

  onAnyValueChanged() {
    this.buildAccordionTabs();
  }

  componentDidMount() {
    this.registerForValueChanges();
    this.buildAccordionTabs();
  }

  componentWillUnmount() {
    this.unregisterForValueChanges();
  }

  onChildValueChanged() {
    this.buildAccordionTabs();
  }

  onDelete(e, index) {
    const v = this.qCtrl().getValueForField(this.question().repeatedElement);
    if (index > -1) {
      v.splice(index, 1);
    }

    this.qCtrl().setValueForQuestion(this.question(), v, v);
    setTimeout(() => this.buildAccordionTabs(), 300);
  }

  createNewEntry() {
    let v = this.qCtrl().getValueForField(this.question().repeatedElement);
    if (!v) {
      v = [];
    }
    const newElement = {
      _id: generateRandomObjectId()
    };
    v.push(newElement);
    this.qCtrl().setValueForQuestion(this.question(), v, v);
    setTimeout(() => this.buildAccordionTabs(), 300);
  }

  render() {
    const accordionTabs = this.state.accordionTabs;
    return <Container fluid={true} className={"text-container "+(this.hasError() ? "error" : "")}>
              <Row>
                <Col className={"p-1"} md={12}>

                  <Accordion onDelete={(e, index) => this.onDelete(e, index)} accordionTabs={accordionTabs} {...this.props}></Accordion>

                  {this.question().creatable == true && <div><Button onClick={(e) => this.createNewEntry(e)} >Neuer Eintrag</Button></div>}

                  {this.renderErrors()}
                </Col>
              </Row>
            </Container>;
  }


  generateTargetField(repeatedQuestion, repeatedElement, value, q) {
    let compinedField = null;
    if (repeatedQuestion && repeatedQuestion.targetField && q.targetField && value && value.element) {
      compinedField = repeatedQuestion.targetField.id+".["+value.element._id+"]."+q.targetField.id;
    }
    if (repeatedElement && repeatedElement.id && q.targetField && value) {
      compinedField = repeatedElement.id+".["+(value && value._id ? value._id : "")+"]."+q.targetField.id;
    }

    return compinedField;
  }

}

QuestionRepeated.propTypes = {

};


export default QuestionRepeated;
