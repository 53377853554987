import React, { useState } from 'react';
import useEyeDropper from 'use-eye-dropper';

const EyeDropper = (props) => {
  const { open, close, isSupported } = useEyeDropper();
  const [color, setColor] = useState('#fff');
  const [error, setError] = useState();
  // useEyeDropper will reject/cleanup the open() promise on unmount,
  // so setState never fires when the component is unmounted.
  const pickColor = () => {
    open()
      .then((color) => {
        setColor(color.sRGBHex);
        props.onColorSelected(color.sRGBHex);
      })
      .catch((e) => {
        // Ensures component is still mounted
        // before calling setState
        if (!e.canceled) setError(e);
      });
  };

  return (
    <>
      {isSupported() ? (
        <button onClick={pickColor}>Farbe wählen</button>
      ) : (
        <span>
          Verwende Chrome, Edge oder Brave, um alle Funktionen zu nutzen
        </span>
      )}
      {!!error && <span>{error.message}</span>}
    </>
  );
};

export default EyeDropper;
