import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaLessThan } from 'react-icons/fa';

class ProjectNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getCategories() {
        return this.props.categories;
    }

    qCtrl() {
        return this.props.qctrl;
    }

    getCustomerNameFromZoho() {
        const zohoCustomerData = this.qCtrl().getZohoCustomerData();
        if (zohoCustomerData) {
            return zohoCustomerData["company"] + " - "+zohoCustomerData["firstname"] + " " + zohoCustomerData["surname"];
        }
        return null;
    }

    showResultsPage(e) {
        this.props.onResultsPageClicked(e);
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    render() {
        return <Container className={"projectnav p-1"} fluid={true}>
                <Row >
                    <Col md={7}>
                        <FaLessThan size={14} /> Kunde: {this.getCustomerNameFromZoho()} ({this.props.name})
                    </Col>
                    <Col md={5} className={"resultspage-button float-right"}>
                        <a href={"#"} onClick={(e) => this.showResultsPage(e)}>Zusammenfassung</a>
                    </Col>
                </Row>
            </Container>
        ;
    }
};

ProjectNavigation.propTypes = {

};


export default ProjectNavigation;
