import React, { Component } from "react";
import { Container } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import LoadingOverlay from "react-loading-overlay";
import ResultsTodos from "../components/results/ResultTodos";
import ResultsAnswers from "../components/results/ResultAnswers";
import Universal20Service, { getAccessToken } from "../services/Universal20Service";
import QuestionController from "../controller/QuestionController";


export default class LoginPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
    };

    this.questionController = new QuestionController(this);
  }

  componentDidMount() {
    const access_token = getAccessToken();
    if (access_token) {
      window.parent.postMessage({
        type: "webconfig",
        data: {
          access_token: access_token
        }
      }, "*");
    }
    else {
      new Universal20Service(this.questionController).redirectToLogin({sendMessageWhenReady: true});
    }


  }

  render() {



    return <Container fluid={true} className={"answer-container p-0"}>
      Login Seite
    </Container>;
  }
}
