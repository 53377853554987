import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Spinner } from "reactstrap";
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import Universal20Service from "../../services/Universal20Service";

class QuestionAddProduct extends QuestionType {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, this.state, {
      product: null,
      loading: false
    });
  }

  hasReset() {
    return false;
  }

  componentDidMount() {
    const u20 = new Universal20Service();
    this.setState({loading: true});
    u20.getProductInfo(this.question().product.id)
      .then((product) => {
        this.setState({product: product, loading: false});
      });
  }

  price() {
    let discount = 1;
    if (this.question().discount) {
      discount = parseFloat(this.question().discount);
    }
    return new Number(this.state.product.TotalPrice*parseInt(this.question().amount)*discount).toFixed(2);
  }

  render() {
    return <div>
      <div className={"add-new-product"}>
        <h6>Es wird ein neues Produkt in Ihre Bestellung eingefügt.</h6>
        {this.state.loading && <Spinner color={"primary"} />}
        {this.state.product != null &&
        <div className={"product-info"}>
          <div className={"row"}>
            <div className={"col-md-2 amount"}>
              <b>{this.question().amount} x</b>
            </div>
            <div className={"col-md-6"}>
              <h5>{this.state.product.Name}</h5>
              <span dangerouslySetInnerHTML={{__html: this.question().productText}}></span>
            </div>
            <div className={"col-md-3 price"}>
              <b>{this.price()} &euro;</b>
            </div>
          </div>

        </div>}

      </div>
    </div>;
  }
};

QuestionAddProduct.propTypes = {

};


export default QuestionAddProduct;
