
import React, {Component} from 'react';
import Container from "reactstrap/lib/Container";
import Button from "reactstrap/lib/Button";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { DOWNLOAD_API } from "../../config";

export default class ImageNumberMapping extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    return <div>
      {this.props.children}<div className={"image-mapping-id-overlay"}>{(this.props.nr) ? this.props.nr : null}</div>
    </div>;
  }
}
