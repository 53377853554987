import React, { Component } from "react";
import Question from "./formular/Question";
import { FaArrowDown, FaChevronDown, FaChevronRight, FaPalette, FaPen, FaWrench } from "react-icons/all";
import { scrollIfNeeded, scrollToElm } from "../helper/helper";
import { Button } from "reactstrap";


export default class Accordion extends Component {

  constructor(props) {
    super(props);

    this.state = {
      changedTimer: 0,
      currentTab: null
    };
  }

  qCtrl() {
    return this.props.qctrl;
  }

  onClickHeader(tab, groups) {
    if (this.state.currentTab === tab) {
      this.setState({
        currentTab: null
      });
    }
    else {
      this.setState({
        currentTab: tab
      });
      if (this.props.accordionTabs[tab].questions.length > 0) {
        this.props.qctrl.formular.setState({
          currentCommentQuestion: (this.props.accordionTabs[tab].questions && this.props.accordionTabs[tab].questions.length > 0) ?
            this.props.accordionTabs[tab].questions[0] : this.props.accordionTabs[tab].parentQuestion,
          currentQuestion: this.props.accordionTabs[tab].parentQuestion,
          currentAccordionElement: this.props.accordionTabs[tab].element
        });
      }
    }
    setTimeout(() => {

        scrollIfNeeded(document.getElementById("accordion-tab-"+groups.id), document.getElementById("questionwrapper"));
    }, 200);
  }

  onChildValueChanged() {
    this.setState({changedTimer: new Date().getTime()});
    if (this.props.onChildValueChanged) {
      this.props.onChildValueChanged();
    }
  }

  isCreatable() {
    return this.props.creatable == true;
  }

  deleteTab(e, event, index) {
    event.stopPropagation();
    //this.props.qctrl.deleteGroup(e.element);
    if (confirm("Soll dieser Eintrag wirklich gelöscht werden?")) {
      this.props.onDelete(e, index);
    }
    return false;
  }

  render() {
    const accordionTabs = this.props.accordionTabs;
    const list = [];
    let firstQuestion = null;
    let index =0;
    for (const tab of Object.keys(accordionTabs)) {
      const e = accordionTabs[tab];
      let groupQuestion = null;
      const accordionList = [];
      let firstChildQuestion = null;
      const groups = e.element;
      let metaInfo = {};

      for (const q of e.questions) {
        if (!this.qCtrl().isVisible(q)) {
          continue;
        }
        if (!firstQuestion) {
          firstQuestion = q;
        }
        if (!firstChildQuestion) {
          firstChildQuestion = q;
        }
        groupQuestion = q;
        accordionList.push( <Question {...this.props}
                                      onChildValueChanged={() => this.onChildValueChanged()}
                                      key={q.id}
                                      question={q} ></Question> );
        if (q.question && q.question.metaInfo) {
          metaInfo = Object.assign(metaInfo, q.question.metaInfo);
        }
      }

      e.index = index;
      list.push(
        <div className={"accordion tab"}>
          <div id={"accordion-tab-"+groups.id} className={"accordion-header "+((this.state.currentTab === tab) ? "selected" : "")}
               onClick={(e) => this.onClickHeader(tab, groups)}>
            <div>
              {this.state.currentTab === tab ? <FaChevronDown size={14} /> :
                <FaChevronRight size={14} />} &nbsp;{groups.name}
              {e.creatable == true && <div className={"accordion-delete-button"}><Button onClick={(event) => this.deleteTab(e, event, e.index)} size={"sm"}>Löschen</Button></div>}
              {firstChildQuestion && this.props.qctrl.hasComment(firstChildQuestion) && <div className={"hascomment-badge"}><FaPen size={10} /></div>}
              {metaInfo.parameters && metaInfo.parameters.length > 0 ? <div className={"hasparameter-badge"}><FaWrench size={10} /></div> : null}
            </div>
          </div> 
          {this.state.currentTab === tab && <div>
            {accordionList}
          </div>}
        </div>
      );

      index++;
    }

    if (!firstQuestion) {
      return null;
    }

    return <div className={"accordion container"}>
      {list}
    </div>;
  }
}