import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import ZohoFlowService from '../../services/ZohoFlowService';
import LoadingOverlay from 'react-loading-overlay';
import {
  SHOW_PRICE_TABLE,
  SHOW_WEBSITE_BUY,
  WEBCONFIG_TRADE_MODE,
} from '../../config';

export default class OrderConfirmationZoho extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: false,
      order: false,
      privacy: false,
      hiddenAction: false,
      avContract: false,
      contract: false,
      paymentType: null,
    };
  }

  hasAllQuestionsFinished() {
    return this.qCtrl().hasAnsweredAllQuestions();
  }

  configuration() {
    return this.qCtrl().formular.state.configuration;
  }

  qCtrl() {
    return this.props.qctrl;
  }

  setValueFor(param, state) {
    this.state[param] = state;
    this.setState(this.state);
  }

  hasValue(param, val) {
    return this.state[param] == val;
  }

  isChecked(param) {
    return this.state[param];
  }

  hasPayment() {
    return (
      (this.state.paymentType != null && SHOW_WEBSITE_BUY) || !SHOW_WEBSITE_BUY
    );
  }

  hasAnsweredCheckboxes() {
    if (this.isFinished()) return true;
    return (
      this.state.avContract &&
      this.state.order &&
      this.hasPayment() &&
      this.state.privacy &&
      this.state.contract
    );
  }

  createOrderWithoutConfirmation() {
    this.zoho = new ZohoFlowService();
    this.setState({ waiting: true });
    this.zoho
      .sendDataToZoho(this.qCtrl(), {
        orderType: WEBCONFIG_TRADE_MODE,
        paymentType: this.state.paymentType,
        avContract: this.state.avContract,
        privacy: this.state.privacy,
        contract: this.state.contract,
        orderStatus: 'test',
      })
      .then(() => {
        this.setState({ waiting: false });
      })
      .catch((err) => {
        this.setState({ waiting: false });
      });
  }

  renderPriceRows() {
    const products = this.qCtrl().getSelectedProducts();
    const list = [];
    let sum = 0;

    for (const p of products) {
      list.push(
        <tr>
          <td className={'product_text'}>{p.Text}</td>
          <td className={'product_description'}>{p.Beschreibungstext}</td>
          <td className={'product_price'}>{p.Price} €</td>
        </tr>
      );
      sum = sum + parseFloat(p.Price);
    }

    list.push(
      <tr>
        <td className={'product-summary'} colspan={2}>
          Gesamtsumme
        </td>
        <td className={'product-summary'}>{sum} €</td>
      </tr>
    );

    return list;
  }

  createOrder() {
    this.zoho = new ZohoFlowService();
    this.setState({ waiting: true });
    const configuration = this.qCtrl().getConfiguration();
    this.zoho
      .sendDataToZoho(this.qCtrl(), {
        orderType: configuration.tradeMode,
        webconfigurator_templates: this.qCtrl().getWebconfiguratorTemplates(),
        paymentType: this.state.paymentType,
        avContract: this.state.avContract,
        privacy: this.state.privacy,
        contract: this.state.contract,
        orderStatus: 'ordered',
      })
      .then(() => {
        this.setState({ waiting: false });
      })
      .catch((err) => {
        this.setState({ waiting: false });
      });
  }

  isCompleteFinished() {
    return this.qCtrl().isFinished() && !this.qCtrl().isOnlyTestFinished();
  }

  isFinished() {
    return this.qCtrl().isFinished();
  }

  render() {
    //   {this.state.hiddenAction && <Button onClick={(e) => this.createOrderWithoutConfirmation()} color={"secondary"} disabled={!this.hasAllQuestionsFinished() || this.isFinished()} size={"xs"}>Beauftragen zur Prüfung</Button>}
    const waitingContainer = this.state.waiting && (
      <LoadingOverlay
        active={this.state.waiting}
        spinner
        className={'overload-spinner'}
        text="Schicke Antwort ..."
      >
        <div className={'fullscreen-loader'}>
          <br />
        </div>
      </LoadingOverlay>
    );
    const configuration = this.qCtrl().getConfiguration();
    const id = this.qCtrl().getId();
    return (
      <Container fluid={true} className={'order-page'}>
        {waitingContainer}
        <Row className={'p-4'}>
          <Col md={12}>
            <label>
              <Input
                checked={this.isChecked('contract') || this.isFinished()}
                disabled={this.isFinished()}
                onChange={(e) => this.setValueFor('contract', e.target.checked)}
                type={'checkbox'}
              />
              <h6>
                Ich habe die allgemeinen Vertragsbedingungen zur Kenntnis
                genommen{' '}
                <a
                  target="_blank"
                  href={
                    configuration.contractUrl + id
                  }
                >
                  (mehr lesen…)
                </a>
              </h6>
            </label>
          </Col>
          <Col md={12}>
            <label>
              <Input
                checked={this.isChecked('privacy') || this.isFinished()}
                disabled={this.isFinished()}
                onChange={(e) => this.setValueFor('privacy', e.target.checked)}
                type={'checkbox'}
              />
              <h6>
                Ich habe die Datenschutzrichtlinien zur Kenntnis genommen{' '}
                <a
                  target="_blank"
                  href={'https://www.agentur-id.de/datenschutz'}
                >
                  (mehr lesen…)
                </a>
              </h6>
            </label>
          </Col>
          <Col md={12}>
            <p style={{ fontSize: '120%', marginTop: '10px' }}>
              <strong>
                Datenschutz ist uns wichtig. <br />
                Deshalb schließen wir mit allen Kunden gemäß der DSGVO einen
                AV-Vertrag ab.{' '}
                <a
                  href={
                    configuration.avUrl + id
                  }
                  target="_blank"
                >
                  (AV-Vertrag lesen)
                </a>
              </strong>
            </p>{' '}
            <label>
              <Input
                checked={this.isChecked('avContract') || this.isFinished()}
                disabled={this.isFinished()}
                onChange={(e) =>
                  this.setValueFor('avContract', e.target.checked)
                }
                type={'checkbox'}
              />
              <h6>Vertrag zur Auftragsdatenverarbeitung annehmen</h6>
            </label>
          </Col>
          <Col md={12}>
            <label>
              <Input
                checked={this.isChecked('order') || this.isFinished()}
                disabled={this.isFinished()}
                onChange={(e) => this.setValueFor('order', e.target.checked)}
                type={'checkbox'}
              />
              <h6>Ich bestelle das gewählte Produkt hiermit kostenpflichtig</h6>
            </label>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={'p-4'}>
            Die geltende{' '}
            <a
              target="_blank"
              href={
                'https://webconfig.agentur-id.de/widerruf/' +
                this.qCtrl().getId()
              }
            >
              Widerrufsbelehrung finden Sie hier.
            </a>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={'p-4'}></Col>
          {SHOW_WEBSITE_BUY && (
            <Col md={12} className={'p-0'}>
              {!this.configuration().paymentMethod && <div>
                <h6>Zahlungsweise</h6><p>
                Die Kosten für die Einrichtung und Erstellung der Webseite
                betragen pauschal 1.950, - EUR zzgl. MwSt. und werden 6 Wochen
                nach Abschluss der Webseiten-Konfiguration in Rechnung gestellt.
                Die Servicepauschale für Wartung und Pflege der Webseite wird ab
                dem 13. Monat fällig und beträgt 55,- EUR/Monat bzw. 600,-
                EUR/Jahr zzgl. MwSt.. Darüber hinausgehende Leistungen werden
                separat und nach Aufwand abgerechnet.
              </p></div>}
              {this.configuration().paymentMethod && <div className={"col-md-8"}><p dangerouslySetInnerHTML={{__html: this.configuration().paymentMethod}}>
              </p></div>}
              {this.configuration().paymentOptions && <div>
                <ul>
                  {this.configuration().paymentOptions.map((d) => <li>
                    <label>
                      <Input
                        disabled={this.isFinished()}
                        type={'radio'}
                        onChange={(e) =>
                          this.setValueFor('paymentType', d.id)
                        }
                        checked={this.hasValue('paymentType', d.id)}
                        name={'paymentType'}
                      />
                      {d.label}
                    </label>
                  </li>)}
                </ul></div>}
              {!this.configuration().paymentOptions && <div>
                <ul>
                  <li>
                    <label>
                      <Input
                        disabled={this.isFinished()}
                        type={'radio'}
                        onChange={(e) =>
                          this.setValueFor('paymentType', '1x1500')
                        }
                        checked={this.hasValue('paymentType', '1x1500')}
                        name={'paymentType'}
                      />
                      1 x 1.950 EUR
                    </label>
                  </li>
                  <li>
                    <label>
                      <Input
                        disabled={this.isFinished()}
                        type={'radio'}
                        onChange={(e) =>
                          this.setValueFor('paymentType', '12x125')
                        }
                        checked={this.hasValue('paymentType', '12x125')}
                        name={'paymentType'}
                      />
                      12 x 162,5 EUR
                    </label>
                  </li>
                </ul>
              </div>}
            </Col>
          )}

          {SHOW_PRICE_TABLE && (
            <Col>
              <h6>Folgende Produkte werden kostenpflichtig bestellt:</h6>
              <table>{this.renderPriceRows()}</table>
            </Col>
          )}

          <Col md={12} className={'p-4'}></Col>
          <Col md={12} className={'p-0'}>
            {!this.hasAnsweredCheckboxes() && (
              <div>
                <span className={'error-message'}>
                  Bitte bestätigen Sie die Ihre Bestellung,
                  Datenschutzerklärungen, AGB´s, AV-Vertrag sowie
                  Zahlungsweise..
                </span>
                <br />
                <br />
              </div>
            )}
            {!this.hasAllQuestionsFinished() && (
              <div>
                <span className={'error-message'}>
                  Bitte beantworten Sie erst alle Fragen bevor sie bestellen.
                </span>
                <br />
                <br />
              </div>
            )}

            <Button
              disabled={
                !this.hasAllQuestionsFinished() ||
                !this.hasAnsweredCheckboxes() ||
                this.isCompleteFinished()
              }
              onClick={(e) => this.createOrder()}
              color={'primary'}
              size={'xs'}
            >
              Jetzt bestellen
            </Button>

          </Col>
        </Row>
      </Container>
    );
  }
}
