import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaLessThan, FaScrewdriver } from 'react-icons/fa';
import QuestionPageContainer from './QuestionPageContainer';
import QuestionYesNo from '../questiontypes/QuestionYesNo';
import QuestionMultipleChoice from '../questiontypes/QuestionMultipleChoice';
import QuestionText from '../questiontypes/QuestionText';
import QuestionDomain from '../questiontypes/QuestionDomain';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import QuestionRichText from '../questiontypes/QuestionRichText';
import QuestionColor from '../questiontypes/QuestionColor';
import QuestionSingleChoice from '../questiontypes/QuestionSingleChoice';
import QuestionLink from '../questiontypes/QuestionLink';
import QuestionWYSIWYG from '../questiontypes/QuestionWYSIWYG';
import QuestionFile from '../questiontypes/QuestionFile';

import AirtableHelper from '../../helper/AirtableHelper';
import { BiInfoCircle, BiReset } from 'react-icons/bi';
import QuestionWebsitePreview from '../questiontypes/QuestionWebsitePreview';
import QuestionNumber from '../questiontypes/QuestionNumber';
import QuestionAutosave from '../questiontypes/QuestionAutosave';
import QuestionRanking from '../questiontypes/QuestionRanking';
import QuestionHtml from '../questiontypes/QuestionHtml';
import QuestionRepeated from '../questiontypes/QuestionRepeated';
import QuestionParameter from '../questiontypes/QuestionParameter';
import { withTranslation } from 'react-i18next';
import QuestionHelptext from '../questiontypes/QuestionHelptext';
import { AiFillInfoCircle } from 'react-icons/all';
import QuestionRegex from "../questiontypes/QuestionRegex";
import QuestionAddProduct from "../questiontypes/QuestionaddProduct";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionRef: null,
      showModal: false,
    };
  }

  qCtrl() {
    return this.props.qctrl;
  }

  question() {
    return this.props.question;
  }

  isVisibleByCondition() {
    if (this.qCtrl().checkCondition(this.question())) {
      return true;
    }
    return false;
  }

  renderQuestionByType() {
    try {
      const addProps = {
        setRef: (ref) => this.setState({ questionRef: ref }),
      };
      const type = this.question().Datentyp.toLowerCase().trim();
      if (type === 'bool' || type === 'boolean') {
        return <QuestionYesNo {...addProps} {...this.props}></QuestionYesNo>;
      } else if (type === 'html') {
        return <QuestionHtml {...addProps} {...this.props}></QuestionHtml>;
      } else if (type === 'ranking') {
        return <QuestionRanking {...addProps} {...this.props}></QuestionRanking>;
      } else if (type === 'link') {
        return <QuestionLink {...addProps} {...this.props}></QuestionLink>;
      } else if (type === 'multiselect' || type == 'model') {
        return (
          <QuestionMultipleChoice
            {...addProps}
            {...this.props}
          ></QuestionMultipleChoice>
        );
      } else if (type === 'autosave') {
        return (
          <QuestionAutosave {...addProps} {...this.props}></QuestionAutosave>
        );
      } else if (type === 'select') {
        return (
          <QuestionSingleChoice
            {...addProps}
            {...this.props}
          ></QuestionSingleChoice>
        );
      } else if (type === 'string') {
        return <QuestionText {...addProps} {...this.props}></QuestionText>;
      } else if (type === 'number') {
        return <QuestionNumber {...addProps} {...this.props}></QuestionNumber>;
      } else if (type === 'parameter') {
        return (
          <QuestionParameter {...addProps} {...this.props}></QuestionParameter>
        );
      } else if (type === 'domain') {
        return <QuestionDomain {...addProps} {...this.props}></QuestionDomain>;
      } else if (type === 'addproduct') {
        return <QuestionAddProduct {...addProps} {...this.props}></QuestionAddProduct>;
      }else if (type === 'wysiwyg') {
        return <QuestionWYSIWYG {...addProps} {...this.props}></QuestionWYSIWYG>;
      } else if (type === 'helptext') {
        return (
          <QuestionHelptext {...addProps} {...this.props}></QuestionHelptext>
        );
      } else if (type === 'richtext') {
        return (
          <QuestionRichText {...addProps} {...this.props}></QuestionRichText>
        );
      } else if (type === 'repeated') {
        return (
          <QuestionRepeated {...addProps} {...this.props}></QuestionRepeated>
        );
      } else if (type === 'color') {
        return <QuestionColor {...addProps} {...this.props}></QuestionColor>;
      } else if (type === 'content') {
        return <QuestionWYSIWYG {...addProps} {...this.props}></QuestionWYSIWYG>;
      } else if (type === 'file') {
        return <QuestionFile {...addProps} {...this.props}></QuestionFile>;
      } else if (type === 'regex') {
        return <QuestionRegex {...addProps} {...this.props}></QuestionRegex>;
      } else if (type === 'websitepreview') {
        return (
          <QuestionWebsitePreview
            {...addProps}
            {...this.props}
          ></QuestionWebsitePreview>
        );
      }

      return type;
    } catch (e) {
      console.log('Can not render the datatype for: ', this.question());
    }
  }

  renderBadge() {
    const q = this.question();
    try {
      if (
        typeof q.Badge !== 'undefined' &&
        q.Badge != null &&
        q.Badge.length > 1
      ) {
        return (
          <div className={'question-badge'}>
            <span>{q.Badge}</span>
          </div>
        );
      }
    } catch (e) {}
    return null;
  }

  renderSubText() {
    const q = this.question();
    try {
      if (
        typeof q.Subtext !== 'undefined' &&
        q.Subtext != null &&
        q.Subtext.length > 5
      ) {
        return AirtableHelper.createElementByHtmlRTF(q.Subtext);
      }
    } catch (e) {}
    return null;
  }

  renderAdditionalCss() {
    const q = this.question();
    try {
      if (typeof q.additionalCss !== 'undefined' && q.additionalCss != null) {
        return (
          <style
            dangerouslySetInnerHTML={{ __html: q.additionalCss }}
            id={'additionalCss'}
            type={'text/css'}
          ></style>
        );
      }
    } catch (e) {}
    return null;
  }

  renderAdditionalHtml() {
    const q = this.question();
    try {
      if (typeof q.additionalHtml !== 'undefined' && q.additionalHtml != null) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: this.qCtrl().replaceTemplatePlaceholders(
                q.additionalHtml,
                q
              ),
            }}
          ></div>
        );
      }
    } catch (e) {}
    return null;
  }

  toggleModal() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  }

  onResetQuestion() {
    if (this.state.questionRef) {
      this.state.questionRef.onResetQuestion();
    }
    this.qCtrl().resetQuestion(this.question());
  }

  handleReset() {
    this.onResetQuestion();
    this.toggleModal();
  }

  renderModal() {
    const { showModal } = this.state;
    if (!showModal) {
      return null;
    }

    return (
      <div className="question-modal">
        <div className="question-modal-content">
          <h4>Frage zurücksetzen</h4>
          <p>Möchtest du wirklich deine Eingabe zurücksetzen?</p>
          <button className="btn btn-primary" onClick={() => this.handleReset()}>
            Zurücksetzen
          </button>
          <button className="btn btn-secondary cancel" onClick={() => this.toggleModal()}>
            Abbrechen
          </button>
        </div>
      </div>
    );
  }

  isRequired() {
    return this.qCtrl().isQuestionRequired(this.question());
  }

  requiredText() {
    return this.isRequired() ? <b style={{ color: 'red' }}> *</b> : null;
  }

  render() {
    const t = this.props.t;
    return (
      this.isVisibleByCondition() && (
        <Container className={'single-question-box '} fluid={true}>
          <Row>
            <Col className={'p-1'} md={12}>
              <ReactTooltip
                anchorId={'questiontooltip-' + this.question().id}
                place={'top'}
                className={'max-tooltip'}
                html={this.question().Subtext}
              />
              {this.qCtrl().canReset(this.question()) && (
                <>
                  {this.renderModal()}
                  <div
                    onClick={() => this.toggleModal()}
                    className={'reset-question'}
                    id={'reset-question'}
                  >
                    <BiReset />
                  </div>
                </>
              )}
              {this.question().Text && <h4 style={{ float: 'left' }}>
                {t(this.question().Text)}
                {this.requiredText()}
              </h4>}
              {!this.question().tooltipdisabled &&
                this.question().Subtext &&
                this.question().Subtext.length > 0 && (
                  <div
                    id={'questiontooltip-' + this.question().id}
                    className={'tooltip-info'}
                  >
                    <AiFillInfoCircle size={24} />
                  </div>
                )}
              <div style={{ clear: 'both' }}></div>
              <div className={'question-detail-container'}>
                {this.renderAdditionalCss()}
                {this.renderBadge()}
                {this.question().tooltipdisabled && this.renderSubText()}
                {this.renderQuestionByType()}
                {this.renderAdditionalHtml()}
              </div>
            </Col>
          </Row>
        </Container>
      )
    );
  }
}

Question.propTypes = {};

export default withTranslation()(Question);
