import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import ZohoFlowService from '../../services/ZohoFlowService';
import LoadingOverlay from 'react-loading-overlay';
import {
  SHOW_PRICE_TABLE,
  SHOW_WEBSITE_BUY,
  WEBCONFIG_TRADE_MODE,
} from '../../config';
import Universal20Service from '../../services/Universal20Service';

export default class OrderConfirmationHQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: false,
      order: false,
      privacy: false,
      hiddenAction: false,
      avContract: false,
      contract: false,
      paymentType: null,
    };
  }

  hasAllQuestionsFinished() {
    return this.qCtrl().hasAnsweredAllQuestions();
  }

  qCtrl() {
    return this.props.qctrl;
  }

  setValueFor(param, state) {
    this.state[param] = state;
    this.setState(this.state);
  }

  hasValue(param, val) {
    return this.state[param] == val;
  }

  isChecked(param) {
    return this.state[param];
  }

  createOrder() {
    const u20 = new Universal20Service(this.qCtrl());
    this.setState({ waiting: true });
    const configuration = this.qCtrl().getConfiguration();
    return u20
      .createOrder(this.qCtrl())
      .then(() => {
        this.setState({ waiting: false });
      })
      .catch((err) => {
        alert('Beim abschliessen ist ein Fehler aufgetreten: ' + err.message);
        this.setState({ waiting: false });
      });
  }

  isCompleteFinished() {
    return this.qCtrl().isFinished() && !this.qCtrl().isOnlyTestFinished();
  }

  isFinished() {
    return this.qCtrl().isFinished();
  }

  openDCCockpit() {
    const dcCockpitLink = this.qCtrl().getCockpitLink();
    window.open(dcCockpitLink, '_blank');
  }

  render() {
    //   {this.state.hiddenAction && <Button onClick={(e) => this.createOrderWithoutConfirmation()} color={"secondary"} disabled={!this.hasAllQuestionsFinished() || this.isFinished()} size={"xs"}>Beauftragen zur Prüfung</Button>}
    const waitingContainer = this.state.waiting && (
      <LoadingOverlay
        active={this.state.waiting}
        spinner
        className={'overload-spinner'}
        text="Schicke Antwort ..."
      >
        <div className={'fullscreen-loader'}>
          <br />
        </div>
      </LoadingOverlay>
    );
    const configuration = this.qCtrl().getConfiguration();
    const id = this.qCtrl().getId();
    const dcCockpitLink = this.qCtrl().getCockpitLink();
    return (
      <Container fluid={true} className={'order-page'}>
        {waitingContainer}
        <Row>
          <Col md={12} className={'p-0'}>
            {!this.hasAllQuestionsFinished() && (
              <div>
                <span className={'error-message'}>
                  Bitte beantworten Sie erst alle Fragen bevor sie bestellen.
                </span>
                <br />
                <br />
              </div>
            )}
            <div>
              Dieses Projekt wurde über das Cockpit bestellt. <br />
              Der Abschluss der Bestellung läuft nun weiter über Cockpit.
              <br />
              <br />
              <b>
                Bitte schliessen sie die Konfiguration ab und kehren sie dann zu
                Cockpit zurück.
              </b>
              <br />
              <br />
              <a target={'_blank'} href={dcCockpitLink}>
                zurück zum Cockpit
              </a>
              <br />
              <br />
            </div>
            <Button
              disabled={
                !this.hasAllQuestionsFinished() || this.isCompleteFinished()
              }
              onClick={(e) => this.createOrder()}
              color={'primary'}
              size={'xs'}
            >
              Konfiguration abschliessen
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={() => this.openDCCockpit()}
              disabled={!this.isCompleteFinished()}
            >
              Weiter zu Cockpit
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}
