import React, { Component } from "react";
import { Container } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import LoadingOverlay from "react-loading-overlay";
import ResultsTodos from "../components/results/ResultTodos";
import ResultsAnswers from "../components/results/ResultAnswers";


export default class AnswerPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: "",
    };

    this.dbService = null;
    this.questionController = null;

    this.props.app.loadConfiguration(this);
  }

  qCtrl() {
    return this.questionController;
  }

  render() {

    if (this.state.loading) {
      return <LoadingOverlay
        active={this.state.loading }
        spinner
        className={"overload-spinner"}
        text='Lade Antworten ...'
      >
        <div className={"fullscreen-loader"}><br/></div>
      </LoadingOverlay>;
    }


    return <Container fluid={true} className={"answer-container p-0"}>
            <Row>
              <Col md={12}>
                Kunde: {this.state.name}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ResultsAnswers qctrl={this.questionController}></ResultsAnswers>
              </Col>
            </Row>
          </Container>;
  }
}
