import React, { Component } from "react";
import Question from "./formular/Question";
import {
  FaArrowDown,
  FaChevronDown,
  FaChevronRight,
  FaComment, FaCross,
  FaExclamation,
  FaPen,
  FaPencilRuler, FaSave
} from "react-icons/all";
import { FaCheck, FaDownload, FaTrash } from "react-icons/fa";
import { FileManager, FileUploader } from "reactjs-file-uploader";
import Button from "reactstrap/lib/Button";
import { API_URL, UPLOAD_API } from "../config";
import Universal20Service, { getAccessToken } from "../services/Universal20Service";
import axios from "axios";
import QuestionFile from "./questiontypes/QuestionFile";


export default class FloatingCommentButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      files: [],
    };

    this.uploadFile = this.uploadFile.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.setCurrentCommentFiles = this.setCurrentCommentFiles.bind(this);
  }

  onClickButton(e) {
    this.setState({
      open: !this.state.open
    });
  }

  hasComment() {
    return this.props.qctrl.hasComment();
  }

  setCurrentCommentFiles(files) {
    return this.props.qctrl.setCurrentCommentFiles(files);
  }

  setCurrentCommentText(text) {
    return this.props.qctrl.setCurrentCommentText(text);
  }

  getCurrentQuestion() {
    return this.props.qctrl.getCurrentQuestion();
  }

  getCurrentComment() {
    if (this.props.qctrl) {
      const c = this.props.qctrl.getCurrentComment();
      if (c) {
        return c;
      }
    }
    return {
      text: "",
      files: []
    };
  }

  getAllUploads() {
    let value =  this.getCurrentComment().files;

    return value;
  }


  hasUploadedFiles() {
    if (this.getAllUploads().length <= 0) {
      return false;
    }
    return true;
  }

  qCtrl() {
    return this.props.qctrl;
  }

  onFileSelected(event) {
    const fileList = Array.from(event.target.files);
    const mapppedList = fileList.map((file) => {
      return {
        filename: file.name,
        fileInfo: file,
        id: QuestionFile.buildID(file)
      };
    });

    this.setState({files: this.state.files.concat(mapppedList)});
  }

  onFileUploaded(file, idata, data) {
    const responsedata = data.response;
    const filecreated = {
      name: file.filename,
      type: responsedata.files[0].mimeType,
      url: responsedata.files[0].url,
      id: responsedata.files[0]._id
    };
    this.setState({
      files: this.state.files.filter((f) => f.id != file.id),
    });
    let all = this.getCurrentComment().files;
    if (!all) {
      all = [];
    }
    all.push(filecreated);
    this.setCurrentCommentFiles(all);

  }

  renderAllUploadedFiles() {
    return this.getAllUploads()
      .map((u) => {
        return <div className={"upload-file-line row"}>
          <div className={"col-md-8 filename"}> {u.name}</div>
          <div className={"col-md-4"}><a href={API_URL+u.url} target={"_blank"}><FaDownload /></a>
            <Button  onClick={(e) => this.deleteFile(u)}  color={"none"} size={"sm"}><FaTrash /></Button></div>
        </div>;
      });
  }

  uploadFile(file) {
    return (
      <FileUploader
        key={file.key}
        file={file}
        headers={{Authorization: "Bearer "+getAccessToken()}}
        onDownloadComplete={(d, e) => this.onFileUploaded(file, d, e)}
        autoUpload={true}
        url={API_URL+UPLOAD_API+"/"+this.qCtrl().getId()+"/"+Universal20Service.getHostnameSubdomain()}
        formData={{
          file: file.fileInfo,
          id: file.id
        }}
        readFile
      >
        {QuestionFile.fileProgress}
      </FileUploader>
    );
  }


  downloadFile(file) {
    const uri = API_URL+"/attachments"+file.id;
    var link = document.createElement("a", {target: "_blank"});
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', file.name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  deleteFile(file) {
    if (confirm("Sind sie sicher dass der Anhang gelöscht werden soll?")) {
      const files = this.getAllUploads().filter((f) => f.id != file.id);
      const value = {
        downloadedFiles: [
          ...files
        ]
      };
      axios.delete(API_URL+"/api/configurators/upload/"+file.id, {headers: {
          Authorization: "Bearer "+getAccessToken()
        }})
        .then(() => {

          this.setCurrentCommentFiles(files);
        });
      this.setCurrentCommentFiles(files);
      return value;
    }
  }

  uploadFiles(files) {
    const _this = this;
    return files.map((file) => _this.uploadFile(file));
  }

  getUploadingFiles() {
    return this.state.files;
  }

  onClickUploadFile(e) {
    document.getElementById("comment-uploadinput").click();
  }

  render() {
    return <div  className={"floating-comment-container"}>
      <div className={"floating-comment-box "+((this.state.open) ? "" : "hidden")}>
        <div>
          <textarea disabled={this.props.qctrl.isFinished()} onChange={(e) => this.setCurrentCommentText(e.target.value)}
                    value={this.getCurrentComment().text} rows={4} />
        </div>
        <div className={"file-upload-container"}>
          <div>
            <input
              type="file"
              onChange={event => this.onFileSelected(event)}
              multiple
              id={"comment-uploadinput"}
              style={{display: "none"}}
            />
            {!this.props.qctrl.isFinished() && <Button className={"upload-button"} color={"primary"} onClick={(e) => this.onClickUploadFile(e)} size={"sm"}>
              Datei hochladen
            </Button>}
          </div>
          <div
            className={"upload-block"}>
            <FileManager
              files={this.getUploadingFiles()}
            >
              {this.uploadFiles}
            </FileManager>
          </div>
          <div>
            {this.renderAllUploadedFiles()}
          </div>
        </div>
      </div>
      <div>
        <div className={"floating-comment-bubble"} onClick={(e) => this.onClickButton(e)}>
          {!this.state.open && <span><FaComment  /> Kommentar {this.props.qctrl.isFinished() ? <span>lesen</span> : <span>schreiben</span>}.</span>}
          {this.state.open && <span><FaCheck  /> {this.props.qctrl.isFinished() ? <span>Schliessen</span> : <span>Speichern</span>}.</span>}
          {this.hasComment() && !this.state.open && <div className={"notification-bubble"}><FaPen size={13} /></div>}
        </div>
      </div>
    </div>;
  }
}