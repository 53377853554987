import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import QuestionType from "./QuestionType";
import Handlebars from "handlebars";

class QuestionWebsitePreview extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getValue() {
    return null;
  }

  getUrl() {
    const value = this.question().value;
    if (this.question().previewURL) {
      const purl = this.question().previewURL;
      try {
        var template = Handlebars.compile((purl) ? purl : "");
        return template(value);
      }
      catch(e) {}

    }
    return "";
  }

  componentDidMount() {
    if (this.qCtrl().formular.state.websitePreview == null ||
      this.qCtrl().formular.state.websitePreview.id !== this.question().id) {
      this.qCtrl().formular.setState({websitePreview: {
          id: this.question().id,
          previewURL: this.getUrl()
        }, reloadTime: new Date().getTime()});
    }
  }

  render() {
    return null;
  }
};

QuestionWebsitePreview.propTypes = {

};


export default QuestionWebsitePreview;
