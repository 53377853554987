import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Error from '../errors/Error';
import Input from 'reactstrap/lib/Input';
import { DynamicMappingDataQuery } from '@agenturid/u20tools';
import { QuestionDataHelper } from "../../controller/QuestionDataHelper";
const Handlebars = require('handlebars');
var stringSimilarity = require('string-similarity');

export default class QuestionType extends Component {
  constructor(props) {
    super(props);
    props.setRef(this);

    this.state = {
      searchText: null,
      update: 0,
    };
  }

  messageReceived(e) {
    if (e.data && e.data.type === 'update') {
      if (this.onAnyValueChanged) {
        this.onAnyValueChanged(e.data.value, e.data.valueObject, e.data.changed);
      }
    }
  }

  registerForValueChanges() {
    window.addEventListener("message", this.messageReceived.bind(this), false);
  }

  unregisterForValueChanges() {
    window.removeEventListener("message", this.messageReceived.bind(this), false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.lastFieldChanged != null &&
      this.props.lastFieldChanged != null &&
      prevProps.lastFieldChanged.changeDate <
        this.props.lastFieldChanged.changeDate
    ) {
      this.setState({ update: this.state.update + 1 });
      this.updateFields();
    }
  }

  updateFields() {}


  loadOptions() {
    //if (!this.state.options) {
    let o = new QuestionDataHelper(this.qCtrl())
      .loadOptionsFromQuestion(this.question(), this.isHierarchical());

    this.setState({
      options: o,
    });
    //}
  }

  renderSearchbar() {
    return (
      <Row className={'searchbar-container'}>
        <Col md={12}>
          <Input
            type={'text'}
            value={this.state.searchText}
            onChange={(e) => this.setState({ searchText: e.target.value })}
            placeholder={'Suche ...'}
            className={'search'}
          />
        </Col>
      </Row>
    );
  }

  isSearchable() {
    return (
      this.question().searchable === true ||
      (!this.isHierarchical() &&
        this.state.options &&
        this.state.options.length > 15)
    );
  }

  getValue() {
    return this.qCtrl().getValueForQuestion(this.question());
  }

  addValue(value) {
    setTimeout(() => this.postChangeMessage(value, this.question()), 200);
    return this.qCtrl().addValueForQuestion(
      this.question(),
      value,
      this.state.options
    );
  }

  isVorauswahl() {
    let txt = this.getValue();
    if (txt == null && this.hasVorauswahl()) {
      return true;
    }
    return false;
  }

  onVorauswahlCheckClicked(e) {
    this.setValue(this.getVorauswahl());
  }

  hasVorauswahl() {
    return (
      typeof this.question()['Vorauswahl'] !== 'undefined' &&
      this.question()['Vorauswahl'] != null
    );
  }

  getVorauswahl() {
    let placeholder = this.question()['Vorauswahl'];

    if (typeof placeholder === 'string') {
      try {
        var template = Handlebars.compile(placeholder ? placeholder : '');
        const d = Object.assign({}, this.qCtrl().getWebsite(), {
          zoho: this.qCtrl().getZohoCustomerData(),
        });
        return template(d);
      } catch (e) {}
    }
    return placeholder;
  }

  isHierarchical() {
    return this.question().hierarchical === true;
  }

  removeValue(value) {
    setTimeout(() => this.postChangeMessage(value, this.question()), 200);
    return this.qCtrl().removeValueForQuestion(
      this.question(),
      value,
      this.state.options
    );
  }

  hasValue(valset) {
    const val = this.qCtrl().getValueForQuestion(this.question());
    if (val != null) {
      if (Array.isArray(val)) {
        if (
          _.cloneDeep(val)
            .map((r) => (typeof r === 'string' ? r.toLowerCase() : r))
            .includes(valset)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  isDisabled() {
    return this.question().disabled;
  }

  isFinished() {
    return !this.qCtrl().isEditMode() && this.qCtrl().isFinished();
  }

  getError() {
    return this.qCtrl().getError(this.question());
  }

  hasError() {
    return this.qCtrl().getError(this.question()) != null;
  }

  onResetQuestion() {}

  setValueQuick(val, valObject = null, reloadTimer=null) {
    if (reloadTimer) {
      const _this = this;
      clearTimeout(this.reloadTimer);
      this.reloadTimer = setTimeout(() => {
        _this.qCtrl().setValueForQuestion(
          _this.question(),
          val,
          valObject,
          true
        );
      }, reloadTimer);
    }
    clearTimeout(this.rc);
    this.rc = setTimeout(() => {
      if (this.props.onChildValueChanged) {
        this.props.onChildValueChanged(this.question(), val);
      }
    }, 200);
    return this.qCtrl().setValueForQuestion(
      this.question(),
      val,
      valObject,
      false
    );
  }
  postChangeMessage(val, q) {
    window.postMessage({
      type: 'update',
      question: q
    }, '*');
  }

  setValue(val, valObject = null) {
    const changed = this.qCtrl().setValueForQuestion(this.question(), val, valObject);

    clearTimeout(this.rc);
    this.rc = setTimeout(() => {
      if (this.props.onChildValueChanged) {
        this.props.onChildValueChanged(this.question(), val);
      }
    }, 200);
    setTimeout(() => this.postChangeMessage(val, this.question()), 200);
    return changed;
  }

  getOptionParentFieldName() {
    return 'parentItem';
  }

  setFixedValue(q_id, field, value) {
    this.qCtrl().setFixedValue(q_id, field, value);
  }

  getChildOptionsForOption(option, alloptions) {
    const l = [];
    for (const o of alloptions) {
      if (o[this.getOptionParentFieldName()]) {
        const parentId = o[this.getOptionParentFieldName()]._id;
        if (
          option._id === parentId ||
          (option.ref_orig && option.ref_orig === parentId)
        ) {
          o.children = this.getChildOptionsForOption(o, alloptions);
          l.push(o);
        }
      }
    }
    return l;
  }

  getOptionsHierarchical(alloptions) {
    const rootOptions = alloptions.filter(
      (m) =>
        typeof m[this.getOptionParentFieldName()] === 'undefined' ||
        m[this.getOptionParentFieldName()] == null ||
        typeof m[this.getOptionParentFieldName()]._id === 'undefined'
    );
    for (const o of rootOptions) {
      o.children = this.getChildOptionsForOption(o, alloptions);
    }
    return rootOptions;
  }

  containsSearchtext(textToSearchIn, textToSearchFor) {
    try {
      if (textToSearchFor && textToSearchIn) {
        const similarity = stringSimilarity.compareTwoStrings(
          textToSearchFor.toLowerCase(),
          textToSearchIn.toLowerCase()
        );
        if (similarity > 0.2) {
          return true;
        }
      }
    } catch (e) {}

    return false;
  }

  filterOptionsBySearch(options) {
    if (this.state.searchText != null && this.state.searchText.length > 0) {
      return options.filter(
        (o) =>
          this.containsSearchtext(o.text, this.state.searchText) ||
          this.containsSearchtext(o.description, this.state.searchText)
      );
    }
    return options;
  }

  getOptions() {
    const options = this.question()['options'];

    let o = options.filter((m) => m.id != null);
    if (this.isHierarchical()) {
      o = this.getOptionsHierarchical(o);
    }

    return o;
  }

  questionName() {
    return this.question().Bezeichner;
  }

  getId() {
    return this.question().id;
  }

  placeholder() {
    let placeholder = this.question().Placeholder;

    var template = Handlebars.compile(placeholder ? placeholder : '');
    return template({
      zoho: this.qCtrl().getZohoCustomerData(),
    });
  }

  qCtrl() {
    return this.props.qctrl;
  }

  question() {
    return this.props.question;
  }

  renderErrors() {
    if (this.hasError()) {
      return <Error error={this.getError()} />;
    }
    return null;
  }

  render() {
    return null;
  }
}
