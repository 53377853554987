import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";

class QuestionAutosave extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {
      hasSavedAlready: false
    };
  }

  hasReset() {
    return false;
  }

  componentDidMount() {
    if (!this.state.hasSavedAlready) {
      this.qCtrl().saveQuestionsComplete(this.question());
      this.setState({hasSavedAlready: true});
    }
  }

  render() {
    return null;
  }
};

QuestionAutosave.propTypes = {

};


export default QuestionAutosave;
