import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';


export default class Error extends Component {

    constructor(props) {
        super(props);
    }

    error() {
        return this.props.error;
    }

    message() {
        return this.error().text;
    }

    render() {
        return <div className={"error-message"}>
            {this.message()}
        </div>;
    }
}
