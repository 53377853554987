import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import FormularPage from './pages/FormularPage';
import FormularConfigurationService from './services/FormularConfigurationService';
import QuestionController from './controller/QuestionController';
import DBService from './services/DBService';
import AnswerPage from './pages/AnswerPage';
import LoadingOverlay from 'react-loading-overlay';
import { I18nextProvider } from "react-i18next";
import i18next from 'i18next';
import { WEBCONFIG_TRADE_MODE } from './config';
import Universal20Service, {
  saveAccessToken,
} from './services/Universal20Service';
import LoginPage from "./pages/LoginPage";

const formularConfig = new FormularConfigurationService();

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'de',                              // language to use
  defaultNS: "common",
  resources: {
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.loadConfiguration = this.loadConfiguration.bind(this);
  }

  getEditMode() {
    let theURL = new URL(window.location.origin); // create dummy url
    theURL.search = window.location.hash.substring(1);

    let editMode = theURL.searchParams.get('editMode');
    if (editMode != null) {
      editMode = editMode.trim();
      editMode = editMode.toLowerCase();
      if (editMode == 'true') {
        return true;
      }
    }
    return false;
  }

  getPage() {
    let theURL = new URL(window.location.origin); // create dummy url
    theURL.search = window.location.hash.substring(1);

    let page = theURL.searchParams.get('page');
    if (page != null) {
      page = page.trim();
      page = page.toLowerCase();
    }
    return page;
  }

  componentDidMount() {
    //@ts-ignore
    if (
      typeof window !== 'undefined' &&
      typeof window.markerConfig === 'undefined'
    ) {
      //@ts-ignore
      window.markerConfig = {
        destination: '62d51de8eaf0f4eddf34131d',
        source: 'snippet',
      };

      if (this.getPage() == 'login') {
        return null;
      }

      //@ts-ignore
      !(function (e, r, a) {
        if (!e.__Marker) {
          e.__Marker = {};
          var t = [],
            n = { __cs: t }
          ;[
            'show',
            'hide',
            'isVisible',
            'capture',
            'cancelCapture',
            'unload',
            'reload',
            'isExtensionInstalled',
            'setReporter',
            'setCustomData',
            'on',
            'off',
          ].forEach(function (e) {
            n[e] = function () {
              var r = Array.prototype.slice.call(arguments);
              r.unshift(e), t.push(r);
            };
          }),
            (e.Marker = n);
          var s = r.createElement('script')
          ;(s.async = 1), (s.src = 'https://edge.marker.io/latest/shim.js');
          var i = r.getElementsByTagName('script')[0];
          i.parentNode.insertBefore(s, i);
        }
      })(window, document);
    }
  }

  loadConfiguration(cb, infoCallbackMsg) {
    const app = this;

    cb.questionController = new QuestionController(cb);
    // Using the API
    cb.api = formularConfig.api;
    window.qctrl = cb.questionController;
    const accessToken = cb.questionController.getCurrentHashParameter(
      'access_token'
    );
    const state = cb.questionController.getCurrentHashParameter('state');
    if (accessToken && state) {
      const jsonData = JSON.parse(decodeURIComponent(state));

      let reloadingQuery = null;
      if (jsonData.reloadDataAfterLogin) {
        reloadingQuery = jsonData.reloadDataAfterLogin.reloadingQuery;
      }
      // We need to save the AccessToken and redirect
      saveAccessToken(accessToken);
      setTimeout(() => {
        if (window.location.hostname.includes('localhost')) {

          /**
           * This is a special case - due to the allowed auth0 redirect urls we need to rewrite the url locally to the correct one
           * before saving the access token. because otherwise localstorage on the correct url is then "null" and its an endless
           * loop
           */
          if (!window.location.hostname.includes('.')) {
            window.location =
              window.location.protocol +
              '//' +
              jsonData.host +
              window.location.hash ;
            return;
          }
          jsonData.host = jsonData.host;
        }
        window.location =
          window.location.protocol + '//' + jsonData.host + jsonData.hash ;
        window.location.reload();
      }, 200);
      return;
    }

    const cid = cb.questionController.getCurrentHashParameter('cid');
    if (!cid || cid === 'demo') {
      cb.setState({
        loading: false,
        error: (
          <span>
            DEMO MODUS! Damit dieser Modus funktioniert musst du bitte oben bei
            #cid=demo in <br />
            deiner URL einfach 'demo' durch ein entsprechendes Objekt (z.B.
            Webseiten-ID oder HQ Artikel-Position ID) austauschen.{' '}
          </span>
        ),
      });
    } else {
      let devMode = false;
      const devmode_p = cb.questionController.getCurrentHashParameter('devmode');
      if (devmode_p !== null) {
        devMode = true;
      }

      formularConfig.setController(cb.questionController);

      document.addEventListener('visibilitychange', function() {
        if(document.hidden) {
          formularConfig.stopSemaphoreLocking();
          // tab is now inactive
          // temporarily clear timer using clearInterval() / clearTimeout()
        }
        else {
          formularConfig.startSemaphoreLocking();
          // tab is active again
          // restart timers
        }
      });
      formularConfig.startSemaphoreLocking();

      return formularConfig
        .loadConfigAndStore(devMode, infoCallbackMsg)
        .then(() => {
          let currentCategory = null;
          const category = cb.questionController.getCurrentHashParameter(
            'category'
          );
          if (category !== null) {
            currentCategory = category;
          }

          cb.setState({
            devMode: devMode,
            flatQuestions: formularConfig.getFlatQuestions(),
            initialComments: formularConfig.getAllComments(),
            flatQuestionsSorted: formularConfig.getFlatQuestionsSorted(),
            options: formularConfig.getOptions(),
            modelMode: formularConfig.getModelMode(),
            configuration: formularConfig.getConfiguration(),
            website: formularConfig.getWebsiteData(),
            editMode: this.getEditMode(),
            loading: false,
            finished: formularConfig.isFinished() === true ? true : false,
            summaryConfig: formularConfig.getSummaryConfig(),
            translations: formularConfig.getTranslations(),
            zohoCustomerData: null,
            modelSchema: formularConfig.getSchema(),
            answers: formularConfig.getAnswers(),
            name: formularConfig.getCustomerName(),
            currentCategory: currentCategory,
          });

          i18next.init({
            resources: {
              de: {
                common: formularConfig.getTranslations()
              },
            },
          });

          cb.questionController.updateQuestionVisibility();

          cb.setState({
            currentQuestion: cb.questionController.getFirstQuestion(),
          });

          let currentQuestion = null;
          const qid = cb.questionController.getCurrentHashParameter('question');
          if (qid !== null) {
            const question = cb.questionController.findQuestionById(qid);
            if (question != null) {
              currentQuestion = question;
            }
          }

          const cid = cb.questionController.getCurrentHashParameter('cid');
          if (cid !== null) {
            cb.setState({
              cid: cid,
            });

            cb.dbService = new DBService(
              cid,
              Universal20Service.getHostnameSubdomain()
            );

            cb.dbService
              .loadState()
              .then((d) => {
                /*
                              cb.setState({
                                finished: (d.finished  === true) ? true: false,
                                summaryConfig: d.summaryConfig,
                                zohoCustomerData: (d.zoho_customer) ? d.zoho_customer : null,
                                answers: d.answers,
                                name: d.name,
                                loading: false
                              });*/


                const data = window.localStorage.getItem(cid);
                if (data) {
                  window.localStorage.removeItem(cid);
                  try {
                    const reloadData = JSON.parse(data);
                    cb.questionController.reloadAfterRedirect(reloadData);
                  }
                  catch(e) {}

                }

                app.setState({
                  loading: false,
                });
                //cb.questionController.buildDataObjectFromAnswers();
                cb.questionController.updateQuestionVisibility();
                if (currentQuestion != null) {
                  cb.questionController.setCurrentQuestionFromOutside(
                    currentQuestion
                  );
                }
                cb.questionController.initialize();
              })
              .catch((e) => {
                console.error(e);
                cb.setState({
                  loading: false,
                  error:
                    'Fehler beim laden der Configuration aus der Handwerker DB: ' +
                    e.message,
                });
              });
          } else {
            // Show Error
            app.setState({
              loading: false,
            });
            cb.setState({
              loading: false,
            });
          }
        })
        .catch((err) => {
          cb.setState({
            loading: false,
            error:
              'Fehler beim laden der Configuration aus der Datenbank: ' +
              err.message,
          });
        });
    }
  }

  render() {
    if (this.getPage() == 'login') {
      return <LoginPage app={this} />;
    }
    if (this.getPage() == 'answers') {
      return <AnswerPage app={this} />;
    }
    return <I18nextProvider i18n={i18next}>
              <FormularPage app={this} title="" />
            </I18nextProvider>;
  }
}

export default hot(App);
