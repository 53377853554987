import React, {Component} from 'react';
import Container from "reactstrap/lib/Container";
import Button from "reactstrap/lib/Button";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { DOWNLOAD_API } from "../../config";

export default class ResultsAnswers extends Component {

    constructor(props) {
        super(props);

    }

    qCtrl() {
        return this.props.qctrl;
    }

    getResults() {
        return this.qCtrl().getAllResults();
    }

    renderResults() {
        const results = this.getResults();

        const answerReults = [];
        const sortedAnswers = results.answers.sort((a,b) => {
            let sortAKey = 0;
            let sortBKey = 0;
            if (a != null) {
                const q = this.qCtrl().findQuestionById(a.key);
                if (!(typeof q.Antwort_Reihenfolge !== "undefined" && q.Antwort_Reihenfolge != null)) {
                    return 1;
                }
                sortAKey = parseInt(q.Antwort_Reihenfolge);
            }
            if (b != null) {
                const q = this.qCtrl().findQuestionById(b.key);
                if (!(typeof q.Antwort_Reihenfolge !== "undefined" && q.Antwort_Reihenfolge != null)) {
                    return -1;
                }
                sortBKey = parseInt(q.Antwort_Reihenfolge);
            }
            if (sortAKey > sortBKey) return 1;
            if (sortAKey < sortBKey) return -1;
            return 0;

        });
        sortedAnswers.map((d) => {
            let comment = "";
            let answer = <missing>null</missing>;
            let files = "";
            let additionalValue = "";
            let agenturTodos = "";
            const q = this.qCtrl().findQuestionById(d.key);
            if (q != null) {
                if (d.value != null) {

                    if (q.Datentyp.includes("Rich")) {
                        answer = <span dangerouslySetInnerHTML={{__html: d.value.replace("\n", "<br/>")}}></span>;
                    }
                    else if (Array.isArray(d.value)) {
                        answer = <ul>{d.value.map((f) => {
                                    return <li>{f}</li>;
                                })}</ul>;
                    }
                    else {
                        answer = d.value;
                    }
                }

                if (d.comment != null && d.comment != "") {
                    comment = d.comment;
                }

                if (typeof d.uploads !== "undefined" && d.uploads != null && d.uploads.length > 0) {
                    files = <ul>{d.uploads.map((u) => {
                        return <li><a target={"_blank"} href={DOWNLOAD_API+u.id}><span>{u.name}</span></a></li>;
                    })}</ul>;
                }

                if (d.additionalValue != null && d.additionalValue != "") {
                    additionalValue = d.additionalValue;
                }

                if (typeof d.agentur_todos !== "undefined" && d.agentur_todos != null && d.agentur_todos.length > 0) {
                    console.log(d.agentur_todos);
                    agenturTodos = <ul>{d.agentur_todos.map((d) => {
                        return <li>{todo.Text}</li>;
                    })}</ul>;
                }

                answerReults.push(
                      <Row className={"Answer-Row"}>
                        <Col md={3}>
                            {q.Category}.{q.Subcategory} - {q.Bezeichner}
                        </Col>
                        <Col md={4}>
                            {answer}
                        </Col>
                        <Col md={3}>
                            {comment}
                            {additionalValue}
                            {agenturTodos}
                        </Col>
                        <Col md={2}>
                            {files}
                        </Col>
                    </Row>);
            }


        });

        return <div>
            <Row>
                <Col md={12}>
                    <h2>Antworten</h2>
                    {answerReults}
                </Col>
            </Row>
        </div>;
    }

    render() {
        return <Container fluid={true}>
            <Row className={"p-4"}>
                <Col md={12}>
                    {this.renderResults()}
                </Col>
            </Row>
        </Container>;
    }
}
