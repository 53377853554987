import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';
import { geti } from "@agenturid/u20tools";
import QuestionController from "../../controller/QuestionController";
import Question from "../formular/Question";

class QuestionParameter extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {
      parameters: []
    };
  }

  loadParameters() {

    const targetField = this.question().parameterObject.id;

    const elements = this.question().value ? geti(this.question().value, targetField) :
                                             geti(this.qCtrl().getWebsite(), targetField);
    if (elements && elements.parameters) {
      const parameters = elements.parameters;

      if (parameters) {
        this.qCtrl().getParametersDetails(parameters)
          .then((pms) => {

            const parentElement = this.question().parentElement;
            const parameterObject = this.question().parameterObject;
            const parentValue = parentElement ? geti(parentElement, targetField) : elements;

            const parameters = pms.map((p) => QuestionController.convertParameterToQuestion(p,
              parentElement ? geti(parentElement, targetField) : elements));
            const parameterValues = parameters.map((p) => this.qCtrl().getParameterValues(p,
              parentValue,
              parameterObject));

            this.qCtrl().resetMultipleAnswers(parameterValues);
            this.setState({
              parameters: parameters.map((p) => {
                if (!this.question().value) {
                  p.parentElementId = elements._id;
                }
                return p;
              })
            });
          });

      }
      else {
        this.setState({parameters: []});
      }
    }


  }

  componentDidMount() {
    if (this.question().parameterObject) {

      this.loadParameters();

    }

  }

  render() {
    return <Container fluid={true} className={"text-container "+(this.hasError() ? "error" : "")}>
      <Row>
        <Col className={"p-1"} md={12}>

          {this.state.parameters.map((p) => <Question {...this.props} question={p} />)}

        </Col>
      </Row>
    </Container>;
  }
};

QuestionParameter.propTypes = {

};


export default QuestionParameter;
