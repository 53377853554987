import React, {Component} from 'react';
import Container from "reactstrap/lib/Container";
import Button from "reactstrap/lib/Button";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ResultsAnswers from "./ResultAnswers";
import OrderConfirmationZoho from "./OrderConfirmationZoho";
import ResultsTodos from "./ResultTodos";
import CategoryOrderConfirmationZoho from "./CategoryOrderConfirmationZoho";
import OrderConfirmationHQ from "./OrderConfirmationHQ";

export default class ResultsPage extends Component {

    constructor(props) {
        super(props);

    }

    qCtrl() {
        return this.props.qctrl;
    }

    render() {
        return <Container fluid={true} className={"results-container"}>
            <Row className={"p-4"}>
                <Col md={12}>
                    <h1>Zusammenfassung</h1>
                    <ResultsTodos {...this.props}></ResultsTodos>
                    {this.qCtrl().isCategoryMode() && this.qCtrl().getSource() != "dccockpit" && <CategoryOrderConfirmationZoho {...this.props}></CategoryOrderConfirmationZoho>}
                    {!this.qCtrl().isCategoryMode()  && this.qCtrl().getSource() != "dccockpit" && <OrderConfirmationZoho {...this.props}></OrderConfirmationZoho>}
                    {!this.qCtrl().isCategoryMode()  && this.qCtrl().getSource() == "dccockpit" && <OrderConfirmationHQ {...this.props}/>}
                        </Col>
            </Row>

            <div onClick={(e) => this.props.onCloseResults(e)} className={"results-close-button"}>X</div>
        </Container>;
    }
}
