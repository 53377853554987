import FormularConfigurationService from '../services/FormularConfigurationService';
import { ConditionalQueryParser, geti } from "@agenturid/u20tools";
import _, { isObject } from "lodash";
import { compareVersions } from "compare-versions";
import { QuestionDataHelper } from "./QuestionDataHelper";
import { generateRandomId, sendFullWebsiteUpdate, sendMessageToPreview } from "../helper/helper";
import isIndex from "lodash/_isIndex";
import toKey from "lodash/_toKey";
import assignValue from "lodash/_assignValue";
import Universal20Service from "../services/Universal20Service";


export function castPath(path, object) {
  return path.split(".").filter((d) => !d.includes("$"));
}

function seti(modelSchema, object, path, value, customizer) {
  if (!isObject(object)) {
    return object;
  }
  if (!modelSchema) {
    console.info("Current schema is empty");
    return object;
  }
  path = castPath(path, object);

  const length = path.length;
  const lastIndex = length - 1;

  let index = -1;
  let nested = object;

  let lastObject = null;
  let lastKey = null;
  let currentSchema = modelSchema;

  const cleared_key = (s) => {
    return s.replace(/\[/g,  "").replace(/\]/g,  "");
  };

  while (nested != null && ++index < length) {
    let internUpdated = false;
    let key = toKey(path[index]);

    let newValue = value;
    let component_key = null;
    if (!key.startsWith("[") && currentSchema.attributes) {
      const currentSchemaKey = Object.keys(currentSchema.attributes).find((d) => d.toLowerCase() === key.replace(/\]/g, "").replace(/\[/g, "").toLowerCase());
      if (currentSchemaKey) {
        currentSchema = currentSchema.attributes[currentSchemaKey];
      }
    }
    component_key = key.replace(/\-/g,  "").replace(/\_/g,  "") ;

    if (index != lastIndex) {
      let objValue = nested[key];


      if (currentSchema) {
        if (currentSchema.type) {
          // Its a basic type
          if (currentSchema.type == "enumeration") {
            // enumeration is special
          }
          else if (currentSchema.type.startsWith("dyn") && currentSchema.type.endsWith("zone")) {
            if (currentSchema) {
              const componentKey = Object.keys(currentSchema.componentsDetails).find((d) => {
                if (d.split(".")[1].replace("[", "")
                  .replace("]", "").replace(/\-/g, "").replace(/\_/g, "").toLowerCase().includes(key.replace("[", "")
                  .replace("]", "").replace(/\-/g, "").replace(/\_/g, "").toLowerCase())) return true;
                return false;
              });
              if (componentKey) {
                if (key.startsWith("[") && key.endsWith("]")) {
                  const ComponentSchema = currentSchema.componentsDetails[componentKey];
                  const targetComponentName = "Component" + ComponentSchema.collectionName.replace(".", "")
                    .replace(/\-/g, "").replace(/\_/g, "").capitalize();
                  if (nested && Array.isArray(nested)) {
                    const found = nested.find((d) => d.kind.toLowerCase() == targetComponentName.toLowerCase());
                    if (found) {
                      nested = found;
                      currentSchema = ComponentSchema;
                      continue;
                    } else {
                      const newComponent = {
                        kind: targetComponentName,
                        id: generateRandomId()
                      };
                      lastObject[cleared_key(lastKey)].push(newComponent);
                      nested = newComponent;
                      currentSchema = ComponentSchema;
                      continue;
                    }
                  } else {
                    const newComponent = {
                      kind: targetComponentName,
                      id: generateRandomId()
                    };
                    lastObject[cleared_key(lastKey)] = [newComponent];
                    nested = newComponent;
                    currentSchema = ComponentSchema;
                    continue;
                  }
                }
              }

            }
          }
          else {

          }
        }
        else {
          // its a component, collection, or model
          if (currentSchema.clientAttribute && currentSchema.clientAttribute.component) {
              if (currentSchema.clientAttribute.repeatable == true) {
               // Special case for repeatable components
               if (!nested[cleared_key(key)]) {
                 nested[cleared_key(key)] = [];
                 const newElement = {
                   kind: "Component"+currentSchema.collectionName.replace(/\./g, "").capitalize(),
                 };
                 nested[cleared_key(key)].push(newElement);
                 nested = newElement;
                 continue;
               }
               else {
                 const found = nested[cleared_key(key)].find((d) => d.kind.toLowerCase() == ("Component"+currentSchema.collectionName.
                 replace(/\./g, "")).toLowerCase());
                 if (found) {
                    nested = found;
                    continue;
                 }
                 else {
                    const newElement = {
                      kind: "Component"+currentSchema.collectionName.replace(/\./g, "").capitalize(),
                    };
                    nested[cleared_key(key)].push(newElement);
                    nested = newElement;
                    continue;
                 }
               }
              }
          }
          else if (currentSchema.clientAttribute && currentSchema.clientAttribute.collection) {
            if (lastObject) {
              if (key.startsWith("[") && key.endsWith("]")) {
                const target = nested.find((d) => d._id == key.replace("[", "").replace("]", ""));
                if (!target) {

                }
                else {
                  nested = target;
                  continue;
                }
              }
              if (!nested[cleared_key(key)]) {
                lastObject[cleared_key(lastKey)] = [];
                continue;
              }
              else {
                const target = lastObject[cleared_key(lastKey)].find((d) => d._id == newValue);
                if (!target) {
                  nested = {
                    _id: generateRandomId(),
                    kind: currentSchema.collectionName.replace(/-/i, "_")
                  };
                  lastObject[cleared_key(lastKey)].push(nested);
                }
                else {
                  nested = target;
                  continue;
                }
              }
            }
          }
          else if (currentSchema.clientAttribute && currentSchema.clientAttribute.model) {
            if (!nested[cleared_key(key)]) {
              nested[cleared_key(key)] = {
                _id: generateRandomId(),
                kind: currentSchema.collectionName.replace(/-/i, "_"),
              };
              nested = nested[cleared_key(key)];
              continue;
            }
          }
          else {
            console.log("Can not find clientAttribute", currentSchema.clientAttribute);
          }
        }
      }
      else {
        console.warn("No schema found for key " + key);
      }

      newValue = customizer ? customizer(objValue, key, nested) : undefined;
      if (newValue === undefined) {
        newValue = isObject(objValue)
          ? objValue
          : (isIndex(path[index + 1]) ? [] : {});
      }
    }
    else {
      /**
       * This is when its the last element in the path.
       * we convert it to the current type.
       */
      if (currentSchema) {
        if (currentSchema.type) {
          // Its a basic type
          if (currentSchema.type == "enumeration") {
            // enumeration is special
            if (!Array.isArray(newValue)) {
              newValue = [newValue];
            }
          } else if (currentSchema.type.startsWith("dyn") && currentSchema.type.endsWith("zone")) {
            if (currentSchema.components) {
              const convertedValue = newValue.map((d) => {
                const targetComponent = currentSchema.components.find((e) =>
                  e.includes(d.id.toLowerCase()));
                if (targetComponent) {
                  return {
                    kind: "Component"+targetComponent.replace(/\./i, "").replace(/\-/g, "").replace(/\_/g, "").capitalize()
                  };
                }
                return d;
              });
              newValue = convertedValue;
            }
          }
          else {

          }
        }
      }
    }

    assignValue(nested, key, newValue);
    lastKey = key;
    lastObject = nested;
    nested = nested[cleared_key(key)];

  }
  return object;
}

const Handlebars = require('handlebars');

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export default class QuestionController {
  constructor(formularClass) {
    this.formular = formularClass;
  }

  hasSubcategories(category) {
    return category.subcategories.length > 0;
  }

  updateWebsiteTemplates() {
    this.formular.state.website_templates = {};

    if (
      typeof this.formular.state.answers !== 'undefined' &&
      this.formular.state.answers != null
    ) {
      for (const a of Array.from(Object.values(this.formular.state.answers))) {
        if (a) {
          const option = this.getOptionByName(a.value);

          /*if (typeof option !== "undefined" && option != null) {
                        try {
                            if (Array.isArray(option)) {
                                for (const o of option) {
                                    if (typeof o["Web-Configurator-ID"] !== "undefined") {
                                        const mo = JSON.parse(JSON.stringify(a));
                                        mo["Web-Configurator-ID"] = o["Web-Configurator-ID"];
                                        this.formular.state.website_templates[o["id"]] = mo;
                                    }
                                }
                            } else {
                                if (typeof option["Web-Configurator-ID"] !== "undefined") {
                                    this.formular.state.website_templates[option["id"]] = a;
                                }
                            }

                        } catch (e) {
                            console.error(e);
                        }
                    }*/
        }
      }
      this.formular.setState({
        website_templates: this.formular.state.website_templates,
      });
    }
  }

  initialize() {
    this.setCurrentQuestion(this.getCurrentQuestion(), () => {
      if (this.onAnswersUpdated()){
        this.formular.setState({
          saved: false
        });
      }
    });

  }

  updateQuestionVisibility() {
    const current_conditional_map = this.getAnswersMapWithID();
    this.formular.state.current_conditional_map = current_conditional_map;
    this.formular.setState({
      current_conditional_map: current_conditional_map,
    });

    this.updateWebsiteTemplates();

    const elements = this.getAllFlatQuestionsArray();
    const current_elements_flat = elements
      .filter((sc) => {
        let found = false;
        if (!this.isVisible(sc)) {
          found = false;
        } else {
          if (this.isCategoryMode()) {
            if (
              typeof sc.question_category !== 'undefined' &&
              sc.question_category == this.getCurrentCategoryMode()
              ) {
              return true;
            }
            return false;
          }
          return true;
        }
        return found;
      })
      .sort(QuestionController.sortQuestionsReverse);

    let current_elements_hierarchical = FormularConfigurationService.buildHierarchicalQuestions(
      current_elements_flat
    );

    this.formular.state.current_elements_flat = current_elements_flat;
    this.formular.state.current_elements_hierarchical = current_elements_hierarchical;
    this.formular.setState({
      current_elements_flat: current_elements_flat,
      current_elements_hierarchical: current_elements_hierarchical,
    });
  }

  getAllCategories() {
    return this.formular.state.current_elements_hierarchical;
  }

  isEditMode() {
    return this.formular.state.editMode;
  }

  getZohoCustomerData() {
    return this.formular.state.zohoCustomerData;
  }

  getSummaryConfig() {
    return this.formular.state.summaryConfig;
  }

  isOnlyTestFinished() {
    return (
      this.formular.state.summaryConfig &&
      this.formular.state.summaryConfig.orderInfos &&
      this.formular.state.summaryConfig.orderInfos.orderStatus &&
      this.formular.state.summaryConfig.orderInfos.orderStatus == 'test'
    );
  }

  isFinished() {
    if (this.isCategoryMode()) {
      return false;
    }
    return this.formular.state.finished === true;
  }

  answerValues() {
    const amap = {};

    for (let k of Object.keys(this.formular.state.answers)) {
      let v = this.formular.state.answers[k].value;
      if (v && typeof v === 'string') {
        amap[k] = v.toLowerCase();
      } else {
        let ret = v;
        if (Array.isArray(v)) {
          ret = v
            .filter((d) => d)
            .filter((r) => {
              if (typeof r === 'object' && typeof r.checked !== 'undefined') {
                if (r.checked) {
                  return true;
                }
                return false;
              }
              return true;
            })
            .map((r) => (typeof r === 'string' ? r : r.id));
        }
        amap[k] = ret;
      }
    }

    return amap;
  }

  answers() {
    return this.formular.state.answers;
  }

  getCommentsForQuestion(question) {
    if (question != null) {
      this.fillOrCheckAnswerField(question.id);
      return this.formular.state.answers[question.id].comment;
    }
    return null;
  }

  fillOrCheckAnswerField(id) {
    if (typeof this.formular.state.answers[id] === 'undefined') {
      this.formular.state.answers[id] = {
        value: null,
        comment: null,
        saved: true,
        changed: false,
        id: id,
        todos: [],
        uploads: [],
        additionalValue: null,
      };
    }
    if (typeof this.formular.state.answers[id].value === 'undefined') {
      this.formular.state.answers[id] = {
        value: null,
        comment: null,
        id: id,
        saved: true,
        changed: false,
        todos: [],
        uploads: [],
        additionalValue: null,
      };
    }
    if (typeof this.formular.state.answers[id].uploads === 'undefined') {
      this.formular.state.answers[id].uploads = [];
    }

    if (
      typeof this.formular.state.answers[id].additionalValue === 'undefined'
    ) {
      this.formular.state.answers[id].additionalValue = null;
    }
  }

  getUploadsForQuestion(question) {
    if (question != null) {
      this.fillOrCheckAnswerField(question.id);
      return typeof this.formular.state.answers[question.id].uploads !==
      'undefined'
        ? this.formular.state.answers[question.id].uploads
        : [];
    }
    return [];
  }

  setFinished(finished) {
    this.formular.setState({
      finished: finished
    });
  }

  removeFileFromQuestion(question, file) {
    const id = question.id;
    this.fillOrCheckAnswerField(id);
    this.formular.state.answers[id].uploads = this.formular.state.answers[
      id
      ].uploads.filter((f) => f.id != file.id);

    this.formular.setState({
      answers: this.formular.state.answers,
    });

    this.formular
      .onFormularAnswerChangedSuccfessfully(this.formular.state.answers)
      .then(() => {});
  }

  appendUploadToQuestion(question, file) {
    const id = question.id;
    this.fillOrCheckAnswerField(id);
    this.formular.state.answers[id].uploads.push({
      name: file.filename,
      type: file.type,
      id: file.id,
    });
    this.formular.setState({
      answers: this.formular.state.answers,
    });

    this.formular
      .onFormularAnswerChangedSuccfessfully(this.formular.state.answers)
      .then(() => {});
  }

  findTodosForQuestionAndValue(id, val) {
    const options = [];
    try {
      let vals = [val];
      if (Array.isArray(val)) {
        vals = val;
      }
      for (let v of vals) {
        const option = this.getOptionByName(v);
        if (option != null) {
          if (
            typeof option['Handwerker TODO'] !== 'undefined' &&
            option['Handwerker TODO'].length > 0
          ) {
            // There are some toods
            options.push(...option['Handwerker TODO']);
          }
        }
      }
    } catch (e) {}
    return options;
  }

  findProductsForQuestionAndValue(id, val) {
    const options = [];
    try {
      let vals = [val];
      if (Array.isArray(val)) {
        vals = val;
      }
      for (let v of vals) {
        const option = this.getOptionByName(v);
        if (option != null) {
          if (
            typeof option['Verknüpfte Produkte'] !== 'undefined' &&
            option['Verknüpfte Produkte'].length > 0
          ) {
            // There are some toods
            options.push(...option['Verknüpfte Produkte']);
          }
        }
      }
    } catch (e) {}
    return options;
  }

  findAgenturTodosForQuestionAndValue(id, val) {
    const options = [];
    try {
      let vals = [val];
      if (Array.isArray(val)) {
        vals = val;
      }
      for (let v of vals) {
        const option = this.getOptionByName(v);
        if (option != null) {
          if (
            typeof option['Agentur TODO'] !== 'undefined' &&
            option['Agentur TODO'].length > 0
          ) {
            // There are some toods
            options.push(...option['Agentur TODO']);
          }
        }
      }
    } catch (e) {}
    return options;
  }

  replaceTemplatePlaceholders(template_string, question) {
    const template = Handlebars.compile(template_string ? template_string : '');
    const questionDict = {};

    questionDict['answers'] = this.answerValues();
    questionDict['question'] = this.getZohoCustomerData();
    questionDict['zoho'] = this.getZohoCustomerData();
    return template(questionDict);
  }

  getAdditionalData(question) {
    const id = question.id;
    if (
      typeof this.formular.state.answers[id].additionalValue !== 'undefined' &&
      this.formular.state.answers[id].additionalValue != null
    ) {
      return this.formular.state.answers[id].additionalValue;
    }
    return null;
  }

  hasAdditionalData(id) {
    if (
      typeof this.formular.state.answers[id].additionalValue !== 'undefined' &&
      this.formular.state.answers[id].additionalValue != null
    ) {
      return true;
    }
    return false;
  }

  appendAdditionalValue(question, value) {
    const id = question.id;
    this.fillOrCheckAnswerField(question.id);
    this.formular.state.answers[id].additionalValue = value;
    this.formular.setState({
      answers: this.formular.state.answers,
    });

    const q = this.findQuestionById(id);
    this.checkForErrorRemoval(q);

    this.formular
      .onFormularAnswerChangedSuccfessfully(this.formular.state.answers)
      .then(() => {});
  }

  saveInternalAnswer(q, answer) {
    this.formular.setState({
      answers: this.formular.state.answers,
      saved: false,
    });
    window.progressing = true;
  }

  saveQuestionAnswer(q, answer) {
    this.formular.onFormularAnswerChangedSuccfessfully(answer).then(() => {
      this.formular.state.answers[q.id].saved = true;
      this.formular.state.answers[q.id].changed = false;
      this.formular.setState({ answers: this.formular.state.answers });
    });
  }

  static replaceFieldName(field) {
    return field.replace("\[\]", "");
  }

  getLastFieldChanged() {
    return this.formular.state.lastFieldChanged;
  }

  convertRawValueToQuestionValue(value, question) {
    if (question && question.type == "select") {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          return value.map((v) => {
            if (v && v.kind) {
              return v._id;
            }
            return v;
          })[0];
        }
        else {
          return null;
        }
      }
      if (value && value.kind) {
        return value._id;
      }
      return value;
    }
    if (value && Array.isArray(value)) {
      let newVal = [];
      value.map((d) => {
        if (typeof d === 'boolean') {
          newVal.push(d);
          return;
        }
        if (typeof d === 'string') {
          newVal.push({
            id: d,
            checked: true
          });
          return;
        }
        newVal.push(Object.assign({}, d, {
          id: (d.id) ? d.id : d._id
        }));
        return;
      });
      return newVal;
    }

    return value;
  }

  getConvertedValue(value, object, question, valObject, old_element_type) {
    if (valObject) {
      if (typeof valObject === "object" && typeof valObject.id !== "undefined" && typeof valObject.text !== "undefined" &&
        typeof valObject.kind === "undefined") {
        return valObject.id;
      }
      if (typeof valObject === "object" && typeof valObject.id !== "undefined" && typeof valObject.identifier !== "undefined") {
        return valObject.id;
      }
      return valObject;
    }
    let v = value;
    if (Array.isArray(value)) {
      v = value.filter((d) => {
        if (typeof d === "string") return true;
        return d.checked;
      }).
      map((d) => {
        if (typeof d === "string") return d;
        if (question.options) {
          const option = question.options.find((o) => o.id === d.id);
          if (option) {
            return option;
          }
        }
        return d.id;
      });
    }
    else {
      if (value && typeof value === "string" && value.includes("downloadFiles")) {
        try {
          const files = JSON.parse(value);
          if (files.downloadFiles) {
            v = files.downloadFiles.map((d) => {
              d.kind = "attachment";
              return d;
            });
          }
        }
        catch(e) {

        }
      }
    }
    return v;
  }

  renderPreviewLive(obj) {
    sendMessageToPreview(obj);
  }

  findDeepKindInModelSchema(schema, targetKind) {
    if (schema) {
      if (schema.clientAttribute) {
        if (schema.collectionName.toLowerCase() === targetKind.toLowerCase()) {
          return schema;
        }
      }
      if (schema.attributes) {
        for (const k of Object.keys(schema.attributes)) {
          const s = schema.attributes[k];
          if (s.collectionName && (s.collectionName.toLowerCase() === targetKind.toLowerCase() ||
            s.collectionName.toLowerCase() + "s" === targetKind.toLowerCase())) {
            return s;
          }
          if (s.attributes) {
            const s2 = this.findDeepKindInModelSchema(s, targetKind);
            if (s2) {
              return s2;
            }
          }
        }
      }
    }
    return null;
  }

  getModelSchema(targetKind = null) {
    if (targetKind != null) {
      const schema = this.findDeepKindInModelSchema(this.formular.state.modelSchema, targetKind);
      return schema;
    }
    return this.formular.state.modelSchema;
  }

  updateValueInDataObject(obj, answer, question, recalculate, valObject=null, renderPreview=true) {
    try {
      if (recalculate) {
        let field = (answer.field ) ? answer.field.name : null;

        if (!answer.parameter && question.parameter) {
          answer.parameter = question.parameter;
          field = "parameterValues."+question.parentElementId+"."+question.parameter.name;
        }

        if (answer.parameter) {
          if (answer.parameter.targetPath) {
            field = answer.parameter.targetPath;
          }
        }
        let field_name = null;
        let values = null;


        if ((!field || field == "parameters") && question) {
          if (question.fromValue) {
            if (Array.isArray(question.fromValue) && question.fromValue.length >= 1) {
              field = question.fromValue[0];
            }
            else if (Array.isArray(question.fromValue) && question.fromValue.length <= 0) {
              field = "parameterValues."+question.parentElementId+"."+question.name;
            }
          }
          else if (question.parameter) {
            field = "parameterValues."+question.parentElementId+"."+question.parameter.name;
          }
        }

        // SOmetimes it is relevant to dynamically change the target field
        // but not send this field to the backend.
        let dynamic_target_field = field;
        if (question.dynamicTargetField && question.dynamicTargetField != ""
           && question.dynamicTargetField != dynamic_target_field) {
          dynamic_target_field = question.dynamicTargetField;
        }
        else if (question && question.repeatedElement && question.repeatedElement.id) {
          dynamic_target_field = question.repeatedElement.id;
        }

        if (!field) {
          if (answer.question && answer.question.repeatedElement) {
            field = answer.question.repeatedElement.id;
            if (!valObject) {
              valObject = answer.question.value;
            }
          }
        }

        if (field) {
          field_name = QuestionController.replaceFieldName(field);
          values = this.getConvertedValue(answer.value, obj, question, valObject);

          seti(this.getModelSchema(), obj, dynamic_target_field, values);

        }

        if (renderPreview) {
          sendMessageToPreview({
            loading: true
          });
          setTimeout(() => {
            this.updateValueAsynchronWithFullPopulatedObject(obj, field_name, values)
              .then((data) => {
                if (data) {
                  let clonedElement = data;
                  if (clonedElement && typeof clonedElement === "object" &&
                    typeof clonedElement["id"] !== "undefined") {
                    clonedElement = Object.assign({}, data);
                    delete clonedElement["id"];
                  }
                  seti(this.getModelSchema(), obj, field_name, clonedElement);
                  this.formular.setState(
                    {
                      lastFieldChanged: {
                        field: field,
                        changeDate: new Date()
                      },
                      answers: this.formular.state.answers,
                      website: obj
                    },
                    () => this.onAnswersUpdatedAsync()
                  );
                }

                sendFullWebsiteUpdate(obj, this);
              });
          }, 150);
        }

      }

    }
    catch(e) {

    }
    return obj;
  }

  getPageDataByPath(url) {
    return this.formular.getPageDataByPath(url);
  }

  updateWebsiteObjectBeforePreview(website) {

    if (website.page) {
      if (this.formular.state.currentAccordionElement && this.formular.state.currentAccordionElement._id == website.page._id) {
        website.page = Object.assign({}, website.page, this.formular.state.currentAccordionElement);
      }
    }

    return website;
  }



  resetAllQuestionsWithTargetField(fieldTarget, valObjectData, question) {
    let deleteKey = [];
    for (const k of Object.keys(this.formular.state.answers)) {
      const f = this.formular.state.answers[k];
      if (f && f.field && f.field.name) {
        if (f.field.name.startsWith(fieldTarget) && f.field.name != fieldTarget) {
          const q = this.getAllFlatQuestionsArray().find((q) => q.id === k.split("_")[k.split("_").length - 1]);
          deleteKey.push(k);

          const newValue = geti(valObjectData, f.field.name);

          this.formular.state.answers[k].value = this.convertRawValueToQuestionValue(newValue, q);
        }
      }
      if (f && f.field && f.field.id) {
        if (f.field.id.startsWith(fieldTarget) && f.field.id != fieldTarget) {
          const q = this.getAllFlatQuestionsArray().find((q) => q.id === k.split("_")[k.split("_").length - 1]);
          deleteKey.push(k);

          const newValue = geti(valObjectData, f.field.id);
          this.formular.state.answers[k].value = this.convertRawValueToQuestionValue(newValue, q);
        }
      }
    }
    if (Object.keys(deleteKey).length > 0) {
      this.formular.setState({
        answers: this.formular.state.answers
      });
    }
  }

  updateAnswers(id, val, recalculate = true, fixedQuestion = null,
                valObject=null, isVorauswahl=false) {
    let changed = false;
    this.fillOrCheckAnswerField(id);
    let q = (fixedQuestion) ? fixedQuestion : this.findQuestionById(id);
    // We reset all questions that are not in the current accordion
    if (q && q.targetField && valObject) {
      this.resetAllQuestionsWithTargetField(q.targetField.id, {[q.targetField.id]: valObject}, q);
    }
    let additionalValue = null;
    if (this.hasAdditionalData(id)) {
      if (
        !confirm(
          'Es gibt bereits eine Änderung für diese Antwort, wenn sie jetzt die Auswahl ändern werden auch die Änderungen wie z.B. der Text zurückgesetzt. Wollen sie fortfahren?'
        )
      ) {
        return;
      }
      const option = this.getOptionByName(val);
      additionalValue = option['Beispiel-Text'];
    }
    if (additionalValue == null) {
      const option = this.getOptionByName(val);
      if (typeof option !== 'undefined' && option != null) {
        if (typeof option['Beispiel-Text'] !== 'undefined') {
          additionalValue = option['Beispiel-Text'];
        }
      }
    }
    const option = this.getOptionByName(val);

    // check for type conversion
    if (q.type.includes('multiselect')) {
      if (Array.isArray(val)) {
        let newVal = val.map((d) => {
          if (typeof d === 'string') return {
            id: d,
            checked: true
          };
          return d;
        });

        val = [];
        if (Array.isArray(this.formular.state.answers[id].value)) {
          changed = newVal.filter((nv) => this.formular.state.answers[id].value.filter((ov) => {
            if (typeof ov === 'string' && typeof nv !== 'string') return ov === nv.id;
            return ov.id === nv.id && ov.checked === nv.checked;
          }).length <= 0).length > 0;
          for (const nv of newVal) {
            const originalValIndex = this.formular.state.answers[id].value.findIndex((ov) => {
              if (typeof ov === 'string' && typeof nv !== 'string') return ov === nv.id ;
              return ov.id === nv.id;
            });
            const originalVal = this.formular.state.answers[id].value[originalValIndex];
            if (!originalVal) {
              val.push({
                id: nv.id,
                checked: true
              });
            }
            else {
              if (typeof this.formular.state.answers[id].value[originalValIndex] === 'string') {
                this.formular.state.answers[id].value[originalValIndex] = {
                  id: this.formular.state.answers[id].value[originalValIndex],
                  checked: true
                };
              }
              else {
                this.formular.state.answers[id].value[originalValIndex].checked = nv.checked;
              }
            }
          }


          val = this.filterDuplicates([...this.formular.state.answers[id].value, ...newVal]);
        }
        else {
          changed = true;
          val = this.filterDuplicates(newVal);
        }
      }
    }
    else {
      if (q.type.includes('select')) {
        if (Array.isArray(val)) {
          if (val.length > 0) {
            changed = true;
            val = val[0];
          }
        }
      }
      changed = this.formular.state.answers[id].value !== val;
    }
    if (changed) {
     // console.log("The question with name " + q.text + " has changed to: ", val);
    }


      // this.lastChanged = new Date().getTime();

    this.formular.state.answers[id].additionalValue = additionalValue;
    this.formular.state.answers[id].value = val;
    this.formular.state.answers[id].id = id;
    if (q && q.targetField) {
      this.formular.state.answers[id].field = Object.assign({}, q.targetField, q.targetField ? {name: q.targetField.id} : null);
    }
    this.formular.state.answers[id].isVorauswahl = isVorauswahl;
    this.formular.state.answers[id].question = q;
    this.formular.state.answers[id].todos = this.findTodosForQuestionAndValue(
      id,
      val
    );
    this.formular.state.answers[
      id
      ].agentur_todos = this.findAgenturTodosForQuestionAndValue(id, val);
    this.formular.state.answers[
      id
      ].products = this.findProductsForQuestionAndValue(id, val);
    if (changed) {
      this.formular.state.answers[id].changed = true;
      this.formular.state.answers[id].saved = false;
    }
    setTimeout(() => {
      try {
        this.formular.state.unsavedAnswers[id] = JSON.parse(JSON.stringify(this.formular.state.answers[id]));
        if (this.formular.state.unsavedAnswers[id].question) {
          delete this.formular.state.unsavedAnswers[id].question.options;
        }
      }
      catch(e) { }
    }, 600);

    const lastFieldChanged = (q.targetField) ? q.targetField.id : null;

    if (q.parentElement && this.formular.state.currentAccordionElement) {
      if (q.parentElement._id === this.formular.state.currentAccordionElement._id) {
        if (q.targetField) {
          seti(this.getModelSchema(q.parentElement.kind), q.parentElement, q.targetField.id, val);
          this.formular.setState({currentAccordionElement: q.parentElement});
        }
      }
    }

    const website = this.updateValueInDataObject(this.formular.state.website,
      this.formular.state.answers[id], q, recalculate, valObject, !isVorauswahl);
    // relevant for recursive calls
    this.formular.state.website = website;
    if (!isVorauswahl) {
      this.formular.setState(
        {
          lastFieldChanged: {
            field: lastFieldChanged,
            changeDate: new Date()
          },
          answers: this.formular.state.answers,
          website: website
        },
        () => {
            this.onAnswersUpdatedAsync();
        }
      );
      clearTimeout(this.autoUpdateRemovalTimeout);
      this.autoUpdateRemovalTimeout = setTimeout(() => {
        this.checkForErrorRemoval(q);
        //this.buildDataObjectFromAnswers();
        if (recalculate) {
          this.updateQuestionVisibility();

          this.saveInternalAnswer();
          //this.saveQuestionAnswer(q, currentAnswer);
          if (q.autoSave === true) {
            clearTimeout(this.autoSaveTimeout);
            this.autoSaveTimeout = setTimeout(
              () => this.saveQuestionsComplete(),
              300
            );
          }
        }
      }, 500);
    }
    return changed;
  }


  getAllChangedAnswers() {
    const changed = {};
    for (const key of Object.keys(this.formular.state.answers)) {
      const val = this.formular.state.answers[key];
      if (val.changed && !val.saved) {
        let retVal = val;
        changed[key] = retVal;
      }
    }

    /// This routine is used only for "repeatable" questions
    // When for example a repeatable is customized and than later
    // it was "unchecked" we need to drop those customizations to be sure
    // the element will not be saved
    const deleteCauseOfDeactivation = [];
    for (const key of Object.keys(changed)) {
      if (key.includes("_")) {
        try {
          /// The last part of the question contains the repeated quesiton id
          const question_id = key.split("_")[3];
          if (changed[question_id]) {
            const vals = changed[question_id].value;
            if (Array.isArray(vals)) {
              for (const v of vals) {
                if (v.checked === false) {
                  deleteCauseOfDeactivation.push(v.id);
                }
              }
            }
          }
        }
        catch(e) {}
      }
    }
    // Lets remove the unchecked elements and their customization data
    for (const k of Object.keys(changed)) {
      for (const r of deleteCauseOfDeactivation) {
        if (k.includes(r)) {
          delete changed[k];
        }
      }
    }


    return changed;
  }

  getCockpitLink() {
    let staticurl = window.location.protocol+"//"+"cockpit."+window.location.hostname.split(".").slice(1).join(".");
    if (window.location.hostname.includes("localhost")) {
      staticurl = "http://localhost:3000";
    }
    return staticurl+"/dashboard/details/"+this.formular.state.website.hqProjectId;
  }

  getAdditionalProducts() {
    return this.getAllVisibleQuestions(true)
      .filter((q) => {
        return q.type == "addproduct";
      })
      .map((d) => {
        return Object.assign({}, {
          Id: d.product.id,
          amount: d.amount,
          discount: d.discount,
          targetQuestionId: d.Id
        });
      });
  }

  getSource() {
    return this.formular.state.website.source;
  }

  getAllComments() {
    const comments = {};

    for (const key of Object.keys(this.formular.state.answers)) {
      const c = this.formular.state.answers[key];
      if (c.comment) {
        comments[key] = c.comment;
      }
    }

    return comments;
  }

  getAllFixedValues() {
    return Object.assign({}, this.formular.state.fixed_value, this.formular.state.static_values);
  }

  isSaving() {
    return this.formular.state.saving;
  }

  /**
   * Save changed but unsaved question values
   * @param affected_question This can be a "question" that triggered the save (autosave). If it is like that we check for all "vorauswahlen" which are left from this question and copy all to values.
   * @returns {Promise<unknown>}
   */
  saveQuestionsComplete(affected_question) {
    return new Promise((resolve, reject) => {
      try {
        if (!this.isSaving() && this.formular.state.saved == false) {
          this.formular.setState({
            saving: true,
            errorWhileSaving: false,
          });

          if (affected_question) {
            // We copy all "Vorauswahl" (preselect) fields to the "value".
            // But we only do it for questiosn which are "lower" than the affected_question
            const lowerQuestions = this.getAllFlatQuestionsLowerThan(affected_question);
            this.fillValuesWithPreselectValues(lowerQuestions);
          }

          const onlyChangedAnswers = this.getAllChangedAnswers();
          const allCommentsGrouped = this.getAllComments();
          const fixed_values = this.getAllFixedValues();
          this.formular
            .saveFormularAnswers(
              onlyChangedAnswers,
              fixed_values,
              allCommentsGrouped
            )
            .then((data) => {
              /*
              if (data && data.updateQuestions) {
                for (const q of data.updateQuestions) {
                  if (this.formular.state.answers[q.id]) {
                    this.formular.state.answers[q.id].value = q.value;
                    this.formular.state.answers[q.id].saved = true;
                    this.formular.state.answers[q.id].changed = false;
                  }
                }
              }*/

              for (const key of Object.keys(this.formular.state.answers)) {
                if (typeof onlyChangedAnswers[key] !== 'undefined') {
                  // This question was saved - lets unchanged its state
                  this.formular.state.answers[key].saved = true;
                  this.formular.state.answers[key].changed = false;
                }
              }
              const newobject = Object.assign({}, this.formular.state.website, data.element);
              this.formular.setState({
                website: newobject,
                answers: this.formular.state.answers,
                reloadTime: new Date().getTime(),
                saved: true,
                saving: false,
              });
              window.progressing = false;
              return resolve();
            })
            .catch((err) => {
              alert('Fehler beim speichern: ' + err.message);
              reject(err);
              this.formular.setState({
                saved: false,
                errorWhileSaving: true,
                reloadTime: new Date().getTime(),
                saving: false,
              });
            });
        } else {
          return resolve();
        }
      } catch (e) {
        reject(e);
      }
    });
  }
  /*
    buildDataObjectFromAnswers() {
      const currentObject = {};

      for (const k of Object.keys(this.formular.state.answers)) {
        const a = this.formular.state.answers[k];
        if (a.field && a.value) {
          const fieldname = a.field.name.replace('[]', '');
          let v = a.value;
          if (Array.isArray(a.value)) {
            v = a.value.map((v) => v.id);
          }
          _.set(currentObject, fieldname, v);
        }
      }

      this.formular.state.dataObject = currentObject;
      this.formular.setState({ dataObject: currentObject });
    }
  */

  getWebsite() {
    return this.formular.state.website;
  }

  /**
   * What are "dynamic Preselects"
   *
   * You can select "Vorauswahl" Elemente based on some dynamic scripts.
   *
   * For example "select all fields"
   *
   * @returns {undefined}
   */
  getQuestionsWithDynamicPreselect() {
    return this.getAllVisibleQuestions(false, true).filter((q) => {
      return typeof q.DynamicPreselection !== 'undefined';
    });
  }

  executeQuestionWithDynmicPreselect(q, clearBeforeStartOnSelect=false) {
    let changed = false;
    if (q.DynamicPreselection) {

      if ((
        typeof this.formular.state.answers[q.id].isVorauswahl === 'undefined' ||
        this.formular.state.answers[q.id].isVorauswahl == true
      )) {
        const d = q.DynamicPreselection;
        if (Array.isArray(d) && d.length > 0) {
          const preselected_options = [];
          for (const d_row of d) {
            if (q.type.includes('multiselect')) {
              if (q.options) {
                for (const option of q.options) {
                  const validationObject = Object.assign(
                    {},
                    this.formular.state.website,
                    { option: option }
                  );
                  const cd = new ConditionalQueryParser(validationObject);
                  const result = cd.parseQuery(d_row.query.jsonQuery);

                  if (result) {
                    preselected_options.push(option.id);
                  }
                }
              }
            } else {
              // Todo - maybe Dynamic Preselection is alos available for other types?
            }
          }

          if (typeof this.formular.state.answers[q.id] === 'undefined') {
            this.formular.state.answers[q.id] = {
              value: null,
            };
          }

          if (q.type.includes('select')) {

            if (
              typeof this.formular.state.answers[q.id].value === 'undefined' ||
              this.formular.state.answers[q.id].value == null
            ) {
              this.formular.state.answers[q.id].value = [];
              this.formular.state.answers[q.id].isVorauswahl = true;
            }

            if (clearBeforeStartOnSelect) {
              this.formular.state.answers[q.id].value = [];
            }
            if (this.setValueForQuestion(q, preselected_options, null, true, true)) {
              changed = true;
            }

          } else {
            if (this.formular.state.answers[q.id].value != v) {
              if (this.setValueForQuestion(q, v, null, false, true)) {
                changed = true;
              }
            }
          }
        }

      }
    }
    return changed;
  }

  filterDuplicates(list) {
    return list.filter((v,i,a)=>a.findIndex(v2=>{
      if (typeof v2 === "string") {
        return v2.toLowerCase() === v.toLowerCase();
      }
      if (typeof v2 === "object") {
        return v2.id === v.id;
      }
      return v2 === v;
    })===i).filter((d) => {
      return d.id !== null && typeof d.id !== "undefined";
    });
  }

  executePreselectQuestion(fq, clearBeforeStartOnSelect=false) {
    let changed = false;
    if (fq.type === 'preselect') {
      for (const f of fq.commands) {
        // Set some preselections to fields
        if (
          typeof this.formular.state.answers[f.question.id] !==
          'undefined'
        ) {
          const q = this.getQuestionById(f.question.id);
          let v = f.value;
          if (typeof v.id !== 'undefined') {
            v = v.id;
          }
          if ((typeof this.formular.state.answers[q.id].isVorauswahl === 'undefined' ||
            this.formular.state.answers[q.id].isVorauswahl == true)) {
            if (q.type.includes('multiselect')) {
              v = v.map((d) => (typeof d.id === 'undefined' ? d : d.id));
              if (
                typeof this.formular.state.answers[q.id]
                  .value === 'undefined' || this.formular.state.answers[q.id]
                  .value == null
              ) {
                this.formular.state.answers[q.id].value = [];
                this.formular.state.answers[q.id].changed = true;
                this.formular.state.answers[q.id].isVorauswahl = true;
              }
              if (clearBeforeStartOnSelect) {
                this.formular.state.answers[q.id].value = [];
              }
              if (this.setValueForQuestion(q, v, null, true, true)) {
                changed = true;
              }
            } else {
              if (this.formular.state.answers[q.id].value != v) {
                if (this.setValueForQuestion(q, v, null, false, true)) {
                  changed = true;
                }
              }

            }
          }

        }
      }
    }
    return changed;
  }

  /**
   * What are SCript Questions?
   *
   * A Script Question is a not-show Question
   */
  getScriptQuestions() {
    return this.getAllVisibleQuestions(false).filter((q) => {
      return q.type === 'preselect' || q.type === 'fieldscript';
    });
  }

  getPreselectQuestions() {
    return this.getAllVisibleQuestions(false).filter((q) => {
      return q.type === 'preselect' || q.type === 'fieldscript';
    });
  }

  onAnswersUpdatedAsync() {
    clearTimeout(this.answerUpdateTimeout);
    this.answerUpdateTimeout = setTimeout(() => this.onAnswersUpdated(), 300);
  }

  setFixedValue(q_id, field, value) {
    this.formular.state.static_values[q_id] = [{
      field: field,
      value: value
    }];
    this.formular.state.website = seti(this.getModelSchema(), this.formular.state.website, field, value);
    this.formular.setState({static_values: this.formular.state.static_values, website: this.formular.state.website});

  }

  executeScriptQuestion(q) {
    this.formular.state.fixed_value[q.id] = q.commands
      .filter((r) => r.field)
      .map((r) => {
        return {
          operator: r.operator ? r.operator.id : null,
          field: r.field.id,
          value: r.value,
        };
      });
  }

  onAnswersUpdated(childrun = false, oldchanges = []) {
    let changed = [];

    const dynamicPreselectQuestions = this.getQuestionsWithDynamicPreselect();
    if (dynamicPreselectQuestions.length > 0) {
      dynamicPreselectQuestions.map((q) => {
        const firstChangeOfQuestion = oldchanges.filter((c) => c.id == q.id).length == 0;
        const c = this.executeQuestionWithDynmicPreselect(q, firstChangeOfQuestion);
        if (c) {
          changed.push(q);
        }
      });
    }

    const visibleScriptQuestions = this.getPreselectQuestions();
    if (visibleScriptQuestions.length > 0) {
      try {
        visibleScriptQuestions.map((q) => {
          const firstChangeOfQuestion = oldchanges.filter((c) => c.id == q.id).length == 0;
          if (this.executePreselectQuestion(q, firstChangeOfQuestion)) {
            changed.push(q);
          }
        });
      } catch (e) {
        console.info(e);
      }
    }

    const allScriptQuestions = this.getScriptQuestions();
    if (allScriptQuestions.length > 0) {
      try {
        this.formular.state.fixed_value = {};
        allScriptQuestions.map((q) => this.executeScriptQuestion(q));
      } catch (e) {
        console.info(e);
      }
    }

    if (changed.length > 0) {
      // We check if it the changed is the already the last in the oldchanges
      // If so, we do not need to call the onAnswersUpdated again
      let countOfSameChanges  = 0;
      if (oldchanges.length > 0) {
        for (const c of changed) {
          const lastChange = oldchanges[oldchanges.length - 1];
          if (lastChange.id == c.id) {
            countOfSameChanges++;
          }
        }
      }
      if (countOfSameChanges == changed.length) {
        console.log("There is a loop in the onAnswersUpdated. For the queston: "+changed[0].title+"("+changed[0].id+") We do not call it again.");
      }
      else {
        this.onAnswersUpdated(true, [...oldchanges, ...changed]);
      }


      if (!childrun) {
        this.formular.setState({
          answers: this.formular.state.answers,
          website: this.formular.state.website
          }, () =>
            this.updateQuestionVisibility()
          );
      }
      return true;
    }
    return false;
  }

  easyCompare(v1, v2) {
    if (v1 && v2) {
      if (Array.isArray(v1)) {
        v1 = v1.join('');
      }
      if (Array.isArray(v2)) {
        v2 = v2.join('');
      }
      v1 = v1
        .toString()
        .replace(/\./g, '')
        .replace(/\_/g, '')
        .replace(/\-/g, '')
        .toLowerCase();
      v2 = v2
        .toString()
        .replace(/\./g, '')
        .replace(/\_/g, '')
        .replace(/\-/g, '')
        .toLowerCase();
      if (v1.length > 0 && v2.length > 0) {
        if (v1.includes(v2) || v2.includes(v1)) {
          return true;
        }
      }
    }
    return false;
  }

  convertArrayValues(value) {
    if (Array.isArray(value)) {
      return value.map((d) => {
        if (typeof d === "string") return {
          id: d,
          checked: true
        };
        return d;
      });
    }
    return value;
  }

  removeValueForQuestion(question, val, options) {
    const id = question.id;
    this.fillOrCheckAnswerField(id);
    let oldValue = this.formular.state.answers[id].value ? JSON.parse(JSON.stringify(this.formular.state.answers[id].value)) : null;
    oldValue = this.convertArrayValues(oldValue);
    this.updateAnswers(id, oldValue, true, question);
    if (
      typeof oldValue === 'undefined' ||
      oldValue == null ||
      !Array.isArray(oldValue)
    ) {
      oldValue = [];
    }
    const index = oldValue.findIndex((d) =>
      this.easyCompare(typeof d === 'string' ? d : d.id, val)
    );
    if (index >= 0) {
      oldValue[index].checked = false;
    }

    if (options) {
      for (const o of options) {
        if (!oldValue.find((d) => d.id === o._id || d.id == o.ref || d.id == o.id)) {
          oldValue.push({
            id: (o._id) ? o._id : (o.id) ? o.id : o.ref,
            checked: false
          });
        }
      }
    }

    this.updateAnswers(id, oldValue, true, question);
  }

  addValueForQuestion(question, val, options, recalculate=true, isVorauswahl=false) {
    let changed = false;
    const id = question.id;
    this.fillOrCheckAnswerField(id);
    let oldValue = this.formular.state.answers[id].value;
    if (
      typeof oldValue === 'undefined' ||
      oldValue == null ||
      !Array.isArray(oldValue)
    ) {
      oldValue = [];
    }

    oldValue = oldValue.filter(
      (d) => d.id !== val
    );

    // Check for "checked" in oldValues before forwarding
    oldValue = oldValue.map((o) => {
      if (typeof o === "string") {
        o = {
          id: o,
          checked: true
        };
      }
      if (typeof o.checked === "undefined") {
        o.checked = true;
      }
      return o;
    });

    oldValue.push({ id: val, checked: true });

    oldValue = oldValue.filter(
      (v, i, a) => a.findIndex((v2) => (v2.id && v.id && v2.id === v.id) ||
        (!v2.id && v.id && v2 == v.id) || (v2.id && !v.id && v2.id == v) || (!v2.id && !v.id && v == v2)) === i
    );

    if (options) {
      for (const o of options) {
        if (!oldValue.find((d) => (d.id && (d.id === o._id || d.id == o.ref)))) {
          oldValue.push({
            id: (o._id) ? o._id : o.ref,
            checked: false
          });
        }
      }
    }

    this.updateAnswers(id, oldValue, recalculate, question, null, isVorauswahl);
    return changed;
  }

  setCurrentCommentFiles(files) {
    const currentQuestion = this.formular.state.currentCommentQuestion;

    this.setCommentFiles(currentQuestion, files);
  }

  setCurrentCommentText(text) {
    const currentQuestion = this.formular.state.currentCommentQuestion;

    this.setComment(currentQuestion, text);
  }

  setCommentFiles(question, files) {
    const id = question.id;
    this.fillOrCheckAnswerField(id);
    if (!this.formular.state.answers[id].comment) {
      this.formular.state.answers[id].comment = {
        text: '',
        files: files,
      };
    } else {
      this.formular.state.answers[id].comment.files = files;
    }
    this.formular.setState(
      {
        answers: this.formular.state.answers,
      },
      () => this.onAnswersUpdated()
    );

    this.saveInternalAnswer();
  }

  setComment(question, comment) {
    const id = question.id;
    this.fillOrCheckAnswerField(id);

    let question_text = question.text;
    if (!question_text) {
      if (question.groupTitle) {
        question_text = question.groupTitle + ' / ' + question.groupName;
      }
    }
    if (!question_text) {
      if (question.parentQuestion) {
        question_text = question.parentQuestion.text;
      }
    }
    if (question.type == "parameter") {
      if (question.value) {
        question_text = question_text + " / " + question.value.name;
      }
    }

    if (!this.formular.state.answers[id].comment) {
      this.formular.state.answers[id].comment = {
        text: comment,
        title: question_text,
        files: [],
      };
    } else {
      this.formular.state.answers[id].comment.text = comment;
      this.formular.state.answers[id].comment.title = question_text;
    }
    this.formular.setState(
      {
        answers: this.formular.state.answers,
      },
      () => this.onAnswersUpdatedAsync()
    );

    this.saveInternalAnswer();
  }

  getApi() {
    return this.formular.api;
  }

  canReset(question) {
    if (question.type === 'websitepreview' || question.type === "autosave" || question.type === "helptext"
      || question.type === "repeated" || question.type == "html" || question.type === "parameter") {
      return false;
    }
    return true;
  }

  hasComment(question) {
    if (typeof question === 'undefined') {
      return (
        this.getCurrentComment() != null &&
        (this.getCurrentComment().files.length > 0 ||
          this.getCurrentComment().text.length > 0)
      );
    } else {
      if (
        this.formular.state.answers[question.id] &&
        typeof this.formular.state.answers[question.id].comment !==
        'undefined' &&
        this.formular.state.answers[question.id].comment != null &&
        (this.formular.state.answers[question.id].comment.text.length > 0 ||
          this.formular.state.answers[question.id].comment.files.length > 0)
      ) {
        return true;
      }
    }
    return false;
  }

  saveBeforeRedirectAndRedirect() {
    this.formular.setState({
      redirectWorking: true
    }, () => {

      if (this.formular.state.saved == false) {
        try {
          let relevantData = {
            unsavedAnswers: this.formular.state.unsavedAnswers,
            saved: this.formular.state.saved,
            saving: this.formular.state.saving,
            lastFieldChanged: this.formular.state.lastFieldChanged,
          };
          window.localStorage.setItem(this.getId(), JSON.stringify(relevantData));
        }
        catch(e) {
          console.error("can not save your current state", e);
        }

      }
      else {
        window.localStorage.setItem(this.getId(), null);
      }
      window.progressing = false;

      let loginHostname = window.location.hostname;
      let loginHash = window.location.pathname+window.location.hash;
      if (window.location.hostname.includes('localhost')) {
        loginHostname = window.location.hostname+":"+window.location.port;
      }
      const redirectUrL = new Universal20Service(this).getRedirectUrl(false, loginHostname, loginHash);
      window.location = redirectUrL;
    });

  }


  reloadAfterRedirect(reloadData) {
    const newState = Object.assign({}, this.formular.state, reloadData);
    this.formular.setState(newState);
    for (const k of Object.keys(reloadData.unsavedAnswers)) {
      try {
        const e = reloadData.unsavedAnswers[k];
        let q = this.getQuestionById(k);
        if (!q) {
          q = e.question;
        }
        if (q) {
          reloadData.unsavedAnswers[k].question = q;
          this.setValueForQuestion(q, e.value);
        }
        else {
          this.formular.state.answers[k] = reloadData.unsavedAnswers[k];
        }
      }
      catch(e) {
        this.formular.state.answers[k] = reloadData.unsavedAnswers[k];
      }
    }
    window.progressing = true;

  }

  resetQuestion(question) {
    const id = question.id;
    if (this.formular.state.answers[id]) {
      delete this.formular.state.answers[id].value;
    }

    this.formular.setState(
      {
        answers: this.formular.state.answers,
      },
      () => {
        this.onAnswersUpdated();
      }
    );
  }

  setValueForQuestion(question, val, valObject = null, recalculate = true, isVorauswahl = false) {
    const changed =  this.updateAnswers(question.id, val, recalculate, question, valObject, isVorauswahl);
    return changed;
  }

  isQuestionAnswered(question) {
    if (this.getValueForQuestion(question) != null) {
      return true;
    }
    return false;
  }

  replaceVariables(link) {
    const answers = this.answerValues();
    const amap = this.getAnswersMapWithID();

    for (const a of amap) {
      try {
        if (answers[a.id] != null) {
          link = link.replace('{$' + a.conditionalId + '}', answers[a.id]);
        }
      } catch (e) {}
    }

    return link;
  }

  getVorauswahlforQuestion(question) {
    if (this.formular.state.answers[question.id] &&
      this.formular.state.answers[question.id].value &&
      this.formular.state.answers[question.id].isVorauswahl) {
      const v = this.formular.state.answers[question.id].value;
      if (typeof v !== 'undefined' && v != null) {
        if (!Array.isArray(v)) {
          return v;
        }
      }
      return v;
    }
    return null;
  }

  getQuestionById(id) {
    const q  = this.getAllFlatQuestionsArray().find((d) => d.id === id);

    return q;
  }

  getParameterValues(parameter, element, parameterElement) {

    const pElement = Object.assign({}, parameterElement);
    pElement.targetPath = "parameters";
    if (parameter.fromValue && Array.isArray(parameter.fromValue) && parameter.fromValue.length > 0) {
      try {
        const d = parameter.fromValue[0];
        pElement.targetPath = d;
        let val = geti(this.formular.state.website, d);

        if (parameter.Datentyp === "multiselect") {
          if (val && !Array.isArray(val)) {
            val = [val];
          }
          else if (!val) {
            val = [];
          }

          val = val.map((r) => {
            if (typeof r === "object") {
              r.checked = true;
              r.id = r._id;
            }
            return r;
          });
        }
        else if (parameter.Datentyp === "select") {
          if (val) {
            if (Array.isArray(val) && val.length > 0) {
              val = val[0];
            }
            if (typeof val === "object") {
              val = val._id;
            }
          }
        }
        else if (parameter.Datentyp === "boolean") {
          if (Array.isArray(val) && val.length > 0) {
            val = val[0];
          }
        }

        if (typeof val !== "undefined") {
          parameter.value = val;
        }

      }
      catch(e) {
        console.log(e);
      }
    }
    else if (element && element._id) {
      if(this.formular.state.website.parameterValues) {
        const pvalues = geti(this.formular.state.website.parameterValues, element._id);
        if (pvalues) {
          parameter.value = pvalues[parameter.name];
        }
      }

    }

    return Object.assign({}, parameter, {parameter: pElement});
  }

  getInitialComments() {
    return this.formular.state.initialComments;
  }

  resetMultipleAnswers(list) {
    const initialComments = this.getInitialComments();
    return list.map((d) => {
      if (!this.formular.state.answers[d.id]) {
        this.fillOrCheckAnswerField(d.id);

        if (initialComments && initialComments[d.id]) {
          const comment = initialComments[d.id];
          this.formular.state.answers[d.id].comment = comment;
        }
        this.formular.state.answers[d.id].value = d.value;
        this.formular.state.answers[d.id].isVorauswahl = true;
        if (d.parameter) {
          this.formular.state.answers[d.id].parameter = d.parameter;
        }
        this.formular.setState({answers: this.formular.state.answers});
      }
    });
  }

  getValueForField(field) {
    if (field) {
      const fieldId = field.id;
      if (fieldId) {
        const e = geti(this.formular.state.website, fieldId);
        if (e) {
          return e;
        }
      }
    }
    return null;
  }

  getValueForQuestion(question) {
    if (question) {
      this.fillOrCheckAnswerField(question.id);
      const v = this.formular.state.answers[question.id].value;
      if (typeof v !== 'undefined' && v != null) {
        if (!Array.isArray(v)) {
          return v;
        }
      }
      return v;
    }
    return null;
  }

  getOptionByName(question, name, ignoreChecked=false) {
    if (typeof name === 'undefined' || name == null) {
      return null;
    }
    if (typeof question === 'boolean') {
      return question;
    }
    if (Array.isArray(name)) {
      const list = [];
      for (let o of name) {
        if (ignoreChecked || (!o.id || (o.id && o.checked == true))) {
          list.push(this.getOptionByName(question, o.id));
        }
      }
      return list;
    }
    if (question.options && question.options.find((o) => o.id === name)) {
      return question.options.find((o) => o.id === name);
    }
    return null;
  }

  getCurrentSubcategories() {
    const category = this.getCurrentCategory();
    return this.getAllVisibleSubcategories(category);
  }

  getCurrentCategory() {
    let index = this.getAllCategories().findIndex(
      (c) => this.getCurrentQuestion().Category == c.id
    );
    if (typeof index === 'undefined' || index == null || index < 0) {
      index = 0;
    }
    return this.getAllCategories()[index];
  }

  getCurrentSubCategory() {
    const currentCat = this.getCurrentCategory();
    const index = currentCat.subcategories.findIndex(
      (c) => this.getCurrentQuestion().Subcategory == c.id
    );
    return currentCat.subcategories[index];
  }

  getCurrentQuestion() {
    return this.formular.state.currentQuestion;
  }

  static sortQuestionsReverse(a, b) {
    let index = typeof a.Index !== 'undefined' ? parseInt(a.Index) : 0;
    const a_id =
      parseInt(a.Category) * 100 + parseInt(a.Subcategory) * 10 + index;
    index = typeof b.Index !== 'undefined' ? parseInt(b.Index) : 0;
    const b_id =
      parseInt(b.Category) * 100 + parseInt(b.Subcategory) * 10 + index;

    if (a_id < b_id) {
      return -1;
    }
    if (b_id < a_id) {
      return 1;
    }
    return 0;
  }

  static sortQuestions(a, b) {
    let index = typeof a.Index !== 'undefined' ? parseInt(a.Index) : 0;
    const a_id =
      parseInt(a.Category) * 100 + parseInt(a.Subcategory) * 10 + index;
    index = typeof b.Index !== 'undefined' ? parseInt(b.Index) : 0;
    const b_id =
      parseInt(b.Category) * 100 + parseInt(b.Subcategory) * 10 + index;
    if (a_id < b_id) {
      return 1;
    }
    if (b_id < a_id) {
      return -1;
    }
    return 0;
  }

  getAllFlatQuestionsArraySorted() {
    return this.formular.state.flatQuestionsSorted;
  }

  getAllFlatQuestionsArray() {
    return Array.from(Object.values(this.formular.state.flatQuestions));
  }

  getAnswersMapWithID() {
    const list = [];
    let last_subcategory = this.getAllFlatQuestionsArray()[0].Subcategory;
    let sc_index = 1;
    for (let e of this.getAllFlatQuestionsArray()) {
      if (last_subcategory != e.Subcategory) {
        sc_index = 1;
      }
      if (this.isCategoryMode()) {
        if (
          typeof e.question_category !== 'undefined' &&
          e.question_category != this.getCurrentCategoryMode()
        ) {
          continue;
        }
      }
      if (typeof e.Index === 'undefined' || e.Index == null || e.Index == '') {
        e.Index = sc_index;
      } else {
        try {
          e.Index = parseInt(e.Index);
        } catch (e) {}
      }
      if (
        typeof e.Category !== 'undefined' &&
        typeof e.Subcategory !== 'undefined' &&
        e.Index
      ) {
        e.conditionalId =
          e.Category.trim() + '.' + e.Subcategory.trim() + '.' + e.Index;
        list.push(e);
      }
      else if (
        typeof e.Category !== 'undefined' &&
        typeof e.Subcategory !== 'undefined'
      ) {
        e.conditionalId = e.Category.trim() + '.' + e.Subcategory.trim();
        list.push(e);
      }

      sc_index++;
      last_subcategory = e.Subcategory;
    }
    return list;
  }

  replaceConditionalsById(condition) {
    let newcondition = condition.toString();
    const amap = this.getAllFlatQuestionsArraySorted();
    if (this.isDevMode()) {
      newcondition = newcondition.replace('0.0.0', '["devmode"]');
    } else {
      newcondition = newcondition.replace('0.0.0', '[]');
    }
    // Wir müssen die Liste sortieren nach der conditionalID weil sonst 1.1.1 von 11.1.1 ersetzt wird und
    // dann bleibt die 1 vorne stehen und die condition klappt nicht.

    for (const a of amap) {
      try {
        newcondition = newcondition.replaceAll(
          a.conditionalId,
          "answers['" + a.id + "']"
        );
      } catch (e) {}
    }

    return newcondition;
  }

  isVisible(c, ignorePreselect = true, invisible_also = false) {
    if (typeof c.elements !== 'undefined' && c.elements.length > 0) {
      c = c.elements[0];
    }
    if (c.invisible === true && !invisible_also) return false;
    if (this.isCategoryMode()) {
      const cat = this.getCurrentCategoryMode();
      if (
        typeof c.question_category !== 'undefined' &&
        c.question_category != cat
      ) {
        return false;
      }
    }
    const conditional = this.checkCondition(c);
    if (conditional) {
      if (ignorePreselect) {
        if (c.type === 'fieldscript') {
          return false;
        }
        if (c.type === 'preselect') {
          return false;
        }
      }
    }
    return conditional;
  }

  isMainCategoryFinished(c) {
    const subcategories = this.getAllVisibleSubcategories(c);
    if (typeof subcategories !== 'undefined') {
      // check for main category
      for (const sc of subcategories) {
        if (!this.hasAnswerForQuestionOrPage(sc)) {
          return false;
        }
      }
      return true;
    }

    return false;
  }

  areQuestionAnswers(list) {
    for (const d of list) {
      if (!this.isQuestionOptional(d)) {
        if (!this.isQuestionAnswered(d)) {
          return false;
        }
      }
    }
    return true;
  }

  isClickable(c) {
    if (this.isCategoryMode()) return true;
    if (typeof c.subcategories !== 'undefined') {
      // check for main category
      const subcat = c.subcategories[0];
      const q = subcat.elements[0];
      // check if the element is in the future and all questions before are answered
      const qbefore = this.getAllFlatQuestionsLowerThan(q);
      if (this.areQuestionAnswers(qbefore)) {
        return true;
      }
      return false;
    } else if (typeof c.elements !== 'undefined') {
      const q = c.elements[0];
      const questionsBefore = this.getAllFlatQuestionsLowerThan(q);
      if (this.areQuestionAnswers(questionsBefore)) {
        return true;
      }
      return false;
    } else {
      const questionsBefore = this.getAllFlatQuestionsLowerThan(c);
      if (this.areQuestionAnswers(questionsBefore)) {
        return true;
      }

      return false;
    }
    return false;
  }

  isDevMode() {
    return this.formular.state.devMode == true;
  }

  isU20Mode() {
    // When configurator.modelMode == "u20mode"
    return this.formular.state.modelMode != "hqMode";
  }

  parseCondition(condition) {
    try {
      const answers = this.answerValues();
      answers['active'] = true;

      /*
      // Maybe there is a problem and not all answers are filled (in category mode)
      // thats why we are checking allq uestions and fill them empty
      try {
        const all_questions = this.getAllFlatQuestionsArraySorted();
        for (const e of all_questions) {
          if (typeof answers[e.id] === 'undefined' || answers[e.id] == null) {
            // Check Vorauswahl and add it as "value" because other wise it would be ignored
            if (
              this.formular.state.flatQuestions[e.id] &&
              this.formular.state.flatQuestions[e.id].value
            ) {
              answers[e.id] = this.formular.state.flatQuestions[e.id].value;
            } else {
              answers[e.id] = [];
            }
          }
        }
      } catch (er) {
        console.error('Error in generating answers', er);
      }

      */

      try {
        const checkData = this.isU20Mode() ? Object.assign({}, answers, this.formular.state.website) : Object.assign({}, this.formular.state.website, answers);
        const d = new ConditionalQueryParser(checkData);

        if (condition.jsonQuery) {
          const e = d.parseQuery(condition.jsonQuery);
          if (e === true) {
            return true;
          }
        }
      } catch (e) {
        console.error('Error in bedingung: ' + condition.raw, e);
      }
    } catch (e) {
      console.error('Error in check for bedingung: ' + condition.raw, e);
    }

    return false;
  }

  checkCondition(q) {
    try {
      if (typeof q.Bedingung === 'undefined' || q.Bedingung === null) {
        return true;
      } else {
        return this.parseCondition(q.Bedingung);
      }
    } catch (e) {}
    return false;
  }

  getCurrentQuestionsForPage() {
    const ele = this.formular.state.currentQuestion;
    for (let c of this.getAllCategories()) {
      for (let sc of c.subcategories) {
        for (let e of sc.elements) {
          if (e.id === ele.id) {
            return sc.elements;
          }
        }
      }
    }

    return [];
  }

  getConfiguration() {
    return this.formular.state.configuration;
  }

  getAllResults() {
    const ansers = [];
    const craftsmenTodos = [];
    const agenturTodos = [];
    const products = [];
    if (
      typeof this.formular.state.answers !== 'undefined' &&
      this.formular.state.answers != null
    ) {
      for (const key of Object.keys(this.formular.state.answers)) {
        const answer = this.formular.state.answers[key];
        const q = this.findQuestionById(key);
        if (q != null) {
          const o = {
            name: q['Bezeichner'],
            id: q['conditionalId'],
            key: key,
            value: answer.value,
            comment: answer.comment,
            todos: answer.todos,
            products: answer.products,
            agentur_todos: answer.agentur_todos,
            uploads: answer.uploads,
            additionalValue: answer.additionalValue,
          };
          if (typeof answer['Web-Configurator-ID'] !== 'undefined') {
            o['Web-Configurator-ID'] = answer['Web-Configurator-ID'];
          }

          ansers.push(o);
          if (answer.todos) {
            for (const t of answer.todos) {
              craftsmenTodos.push(Object.assign({}, t));
            }
          }
          if (answer.agentur_todos) {
            for (const t of answer.agentur_todos) {
              agenturTodos.push(Object.assign({}, t));
            }
          }
          if (answer.products) {
            for (const t of answer.products) {
              products.push(Object.assign({}, t));
            }
          }
        }
      }
    }

    return {
      products: products,
      agenturTodos: agenturTodos,
      craftsmenTodos: craftsmenTodos,
      answers: ansers,
    };
  }

  getSelectedProducts() {
    const results = this.getAllResults();
    return results.products;
  }

  findFirstQuestionByCategory(cat) {
    return this.formular.state.current_elements_flat[0];
  }

  findQuestionById(id) {
    for (let c of this.getAllCategories()) {
      for (let sc of c.subcategories) {
        for (let e of sc.elements) {
          if (e.id === id) {
            return e;
          }
        }
      }
    }
    return null;
  }

  getCurrentHashParameter(hashParameterName) {
    let theURL = new URL(window.location.origin); // create dummy url
    theURL.search = window.location.hash.substring(1);

    return theURL.searchParams.get(hashParameterName);
  }

  getCurrentCategoryModeUpperCase() {
    return this.getCurrentCategoryMode().capitalize();
  }

  getCurrentCategoryMode() {
    return this.formular.state.currentCategory;
  }

  getWebconfiguratorTemplates() {
    return this.formular.state.website_templates;
  }

  isCategoryMode() {
    return this.formular.state.currentCategory != null;
  }

  getOptions(option_list) {
    const list = [];
    const option_list_global = Object.values(this.formular.state.options);
    for (let o of option_list) {
      const index = option_list_global.findIndex(
        (d) => d.id.toLowerCase() === o.toLowerCase()
      );
      if (index >= 0) {
        list.push(option_list_global[index]);
      }
    }
    return list;
  }

  setOrUpdateHashParameter(hashParameterName, hashParameterValue) {
    let theURL = new URL(window.location.origin); // create dummy url
    theURL.search = window.location.hash.substring(1); // copy current hash-parameters without the '#' AS search-parameters
    theURL.searchParams.set(hashParameterName, hashParameterValue); // set or update value with the searchParams-API
    window.location.hash = theURL.searchParams; // Write back as hashparameters
  }

  getFirstQuestion() {
    const e = this.getAllQuestionElementsFlat();
    return e[0];
  }


  isCurrentLastQuestion() {
    const q = this.getCurrentQuestion();
    if (this.findNextQuestionPage(q) == null) {
      return true;
    }
    return false;
  }

  hasAnsweredAllQuestions() {
    let lastQuestion = false;
    let currentQuestion = this.getFirstQuestion();
    const allQuestions = this.getAllVisibleQuestions();
    for (const cq of allQuestions) {
      const error = this.checkQuestionForAnswerAndGenerateError(cq);
      if (error) {
        return false;
      }
    }

    return true;
  }

  findPrevQuestionPage(question) {
    const currentq =
      typeof question !== 'undefined' ? question : this.getCurrentQuestion();

    const currentSubcatIndexNumber =
      parseInt(currentq.Category) * 10 + parseInt(currentq.Subcategory);
    const listOfAllElementsFlat = this.getAllQuestionElementsFlat();
    let selectedQuestion = null;
    for (let i = listOfAllElementsFlat.length - 1; i >= 0; i--) {
      const indexNUmber =
        parseInt(listOfAllElementsFlat[i].Category) * 10 +
        parseInt(listOfAllElementsFlat[i].Subcategory);
      if (indexNUmber < currentSubcatIndexNumber) {
        if (i >= 0) {
          selectedQuestion = listOfAllElementsFlat[i];
          break;
        }
      }
    }

    return selectedQuestion;
  }

  getAllVisibleSubcategories(category) {
    const categories = category.subcategories;
    return categories;
  }

  getAllVisibleElementsOfSubcategory(category) {
    const categories = category.elements;
    return categories;
  }

  getAllQuestionElementsFlatWithoutCategory() {
    const elements = [];
    const cats = this.formular.state.categories;
    for (const c of cats) {
      const subcats = c.subcategories;
      for (const subcat of subcats) {
        const subcatElements = subcat.elements;
        elements.push(...subcatElements);
      }
    }
    return elements;
  }

  getAllQuestionElementsFlat() {
    return this.formular.state.current_elements_flat;
  }

  getAllVisibleQuestions(ignorePreselect = true, invisible_also = false) {
    return this.getAllFlatQuestionsArray().filter((q) =>
      this.isVisible(q, ignorePreselect, invisible_also)
    );
  }

  findNextVisibleQuestionPageFromQuestion(currentq) {
    const currentSubcatIndexNumber =
      parseInt(currentq.Category) * 10 + parseInt(currentq.Subcategory);
    const listOfAllElementsFlat = this.getAllQuestionElementsFlat(currentq);
    let selectedQuestion = null;
    for (let i = 0; i < listOfAllElementsFlat.length; i++) {
      const indexNUmber =
        parseInt(listOfAllElementsFlat[i].Category) * 10 +
        parseInt(listOfAllElementsFlat[i].Subcategory);
      if (indexNUmber >= currentSubcatIndexNumber) {
        if (i < listOfAllElementsFlat.length) {
          selectedQuestion = listOfAllElementsFlat[i];
          break;
        }
      }
    }

    return selectedQuestion;
  }

  findNextQuestionPage(question) {
    const currentq =
      typeof question !== 'undefined' ? question : this.getCurrentQuestion();

    const currentSubcatIndexNumber =
      parseInt(currentq.Category) * 10 + parseInt(currentq.Subcategory);
    const listOfAllElementsFlat = this.getAllQuestionElementsFlat();
    let selectedQuestion = null;
    for (let i = 0; i < listOfAllElementsFlat.length; i++) {
      const indexNUmber =
        parseInt(listOfAllElementsFlat[i].Category) * 10 +
        parseInt(listOfAllElementsFlat[i].Subcategory);
      if (indexNUmber > currentSubcatIndexNumber) {
        if (i < listOfAllElementsFlat.length) {
          selectedQuestion = listOfAllElementsFlat[i];
          break;
        }
      }
    }

    return selectedQuestion;
  }

  setCurrentMainCategory(p) {
    this.setCurrentQuestion(
      this.findNextVisibleQuestionPageFromQuestion(
        p.subcategories[0].elements[0]
      )
    );
  }

  setCurrentQuestionPage(p) {
    this.setCurrentQuestion(p.elements[0]);
  }

  setCurrentQuestionFromOutside(q) {
    if (this.isQuestionAnswered(q)) {
      this.setCurrentQuestion(q);
    }
  }

  setCurrentQuestion(q, cb) {
    this.setOrUpdateHashParameter('question', q.id);
    this.formular.setState({
      currentQuestion: q,
      currentCommentQuestion: q,
      websitePreview: null,
    }, cb);
  }

  getCurrentComment() {
    const currentQuestion = this.formular.state.currentCommentQuestion;
    if (currentQuestion) {
      if (this.formular.state.answers[currentQuestion.id]) {
        return this.formular.state.answers[currentQuestion.id].comment;
      }
    }
    return null;
  }

  checkForUnsavedQuestionsAndSave() {
    const currentQuestions = this.getCurrentQuestionsForPage();

    const answers = this.answers();
    for (const q of currentQuestions) {
      if (typeof answers[q.id] !== 'undefined') {
        if (answers[q.id].saved === false && answers[q.id].changed === true) {
          // this.saveQuestionAnswer(q, answers[q.id]);
          this.saveInternalAnswer(q, answers[q.id]);
        }
      }
    }

    return true;
  }

  nextQuestionPage(cb) {
    if (this.checkForUnsavedQuestionsAndSave()) {
      const next = this.findNextQuestionPage();
      if (next != null) {
        this.setCurrentQuestion(next, cb);
      }
    }
  }

  isFullscreenQuestion() {
    const currentQuestions = this.getCurrentQuestionsForPage();
    if (currentQuestions && currentQuestions.length > 0) {
      const firstQuestion = currentQuestions[0];
      if (firstQuestion.fullscreen) {
        return true;
      }
    }

    return false;
  }

  setErrors(errors) {
    this.formular.setState({ errors: errors });
  }

  isQuestionRequired(question) {
    if (question.type === "repeated" || question.type === "parameter") {
      return false;
    }
    return !this.isQuestionOptional(question);
  }

  isQuestionOptional(question) {
    return (
      question.parameter != null ||
      question.type === 'websitepreview' ||
      question.type === 'autosave' ||
      question.type === 'parameter' ||
      question.type === 'preselect' ||
      question.type === 'addproduct' ||
      question.type === 'helptext' ||
      question.invisible === true ||
      question.type === 'html' ||
      (typeof question['optional'] !== 'undefined' &&
        question['optional'] != null &&
        question['optional'] == true)
    );
  }

  hasAnswerForQuestionOrPage(q_or_c) {
    if (typeof q_or_c.elements !== 'undefined') {
      const elements = this.getAllVisibleElementsOfSubcategory(q_or_c);
      for (let q of elements) {
        if (this.checkQuestionForAnswerAndGenerateError(q) != null) {
          return false;
        }
      }
      return true;
    } else {
      return this.checkQuestionForAnswerAndGenerateError(q_or_c) == null;
    }
  }

  checkQuestionForAnswerAndGenerateError(question) {
    const answers = this.answers();
    if (this.checkCondition(question)) {
      if (!this.isQuestionOptional(question)) {
        if (
          typeof answers[question.id] === 'undefined' ||
          answers[question.id] == null
        ) {
          return {
            missing: true,
            text: question.Error
              ? question.Error
              : 'Bitte fülle dieses Feld aus um fortzufahren.',
          };
        }
        if (answers[question.id].value == null) {
          return {
            missing: true,
            text: question.Error
              ? question.Error
              : 'Bitte fülle dieses Feld aus um fortzufahren.',
          };
        }
        if (
          typeof answers[question.id].value.trim !== 'undefined' &&
          answers[question.id].value.trim() == ''
        ) {
          return {
            missing: true,
            text: question.Error
              ? question.Error
              : 'Bitte fülle dieses Feld aus um fortzufahren.',
          };
        }
        if (
          Array.isArray(answers[question.id].value) &&
          answers[question.id].value.length <= 0
        ) {
          return {
            missing: true,
            text: question.Error
              ? question.Error
              : 'Bitte fülle dieses Feld aus um fortzufahren.',
          };
        }
      }
    }
    return null;
  }

  getId() {
    return this.formular.state.cid;
  }

  getError(question) {
    if (typeof this.formular.state.errors[question.id] !== 'undefined') {
      return this.formular.state.errors[question.id];
    }
    return null;
  }

  getParametersDetails(pms) {
    return this.formular.getParametersDetails(pms);
  }

  checkForErrorRemoval(q) {
    if (typeof this.formular.state.errors[q.id] !== 'undefined') {
      if (this.checkQuestionForAnswerAndGenerateError(q) == null) {
        delete this.formular.state.errors[q.id];
        this.setErrors(this.formular.state.errors);
      }
    }
  }

  checkForAnswersBeforeStep() {
    const errors = {};

    const currentQuestions = this.getCurrentQuestionsForPage();

    for (let i = 0; i < currentQuestions.length; i++) {
      const q = currentQuestions[i];
      const error = this.checkQuestionForAnswerAndGenerateError(q);
      if (error) {
        errors[q.id] = error;
      }
    }

    if (Object.keys(errors).length > 0) {
      this.setErrors(errors);
      return errors;
    }
    this.setErrors({});
    return null;
  }

  stepBack(cb) {
    const next = this.findPrevQuestionPage();
    if (next != null) {
      this.setCurrentQuestion(next, cb);
    }
  }

  static convertParameterToQuestion(p, parentElement) {
    const question = Object.assign({}, p, {
      id: p._id,
      Text: p.name,
      Vorauswahl: null
    });
    if (parentElement){
      question.parentElementId = parentElement._id;
    }
    if (p.options) {
      let type = p.options.type;
      if (Array.isArray(type) && type.length > 0) type = type[0];
      if (type === "enum") {
        type = "select";
        if (p.options.multi) {
          type = "multiselect";
        }
      }
      if (type === "model") {
        type = "select";
      }
      question.Datentyp = type;
      question.options = p.options.values.map((d) => {
        if (typeof d === "string") {
          return {id: d, checked: true, text: d, value: d};
        }
        else {
          d.text = d.name;
        }
        return d;
      });
    }
    else if (question.fromValue == null || (Array.isArray(question.fromValue) && question.fromValue.length <= 0)) {
      if (question.entries) {
        question.options = question.entries.split(/\n/g).map((d) => {
          return {id: d.trim(), checked: false, text: d.trim(), value: d.trim()};
        });
        question.Datentyp = (question.type && Array.isArray(question.type) && question.type.length > 0) ? question.type[0] : "select";
        question.parameter = p;
      }
    }
    return question;
  }

  getAllFlatQuestionsLowerThan(affected_question) {
    return this.formular.state.current_elements_flat.filter((q) =>
    {
      if (compareVersions(q.conditionalId, affected_question.conditionalId) < 0) {
        return true;
      }
      return false;
    });
  }

  setValueForQuestionByType(question, value, valObject, rerender, isVorauswahl) {
    if (question.type.includes("multiselect")) {
      if (Array.isArray(value)) {
        const options = (new QuestionDataHelper(this)).loadOptionsFromQuestion(question);
        value.map((v) => this.addValueForQuestion(question, v, options));
      }
    }
    else {
      this.setValueForQuestion(question, value, valObject, rerender, isVorauswahl);
    }
  }

  fillValuesWithPreselectValues(lowerQuestions) {
    for (const q of lowerQuestions) {
      if (!this.isQuestionAnswered(q)) {
        const vorauswahl = this.getVorauswahlforQuestion(q);
        if (vorauswahl) {
          this.setValueForQuestionByType(q, vorauswahl, null, true, true);
        }
      }

    }
  }

  updateValueAsynchronWithFullPopulatedObject(obj, field_name, values) {
    const _this = this;
    return new Promise((resolve, reject) => {
      try {
        if (values && values.kind && values._id) {
          return (new FormularConfigurationService(_this))
            .loadElementDeep(values)
            .then((data) => {
              if (data) {
                return resolve(data.data);
              }
              return resolve(values);
            });
        }
      }
      catch(e) {
        console.error(e);
      }
      return resolve(values);
    });
  }
}
