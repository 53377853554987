import axios from 'axios';
import {
  WEBCONFIG_HOME_URL,
  WEBCONFIG_TRADE_MODE,
  ZOHO_API,
  ZOHO_EDIT_API,
  ZOHO_API_SAVE_CATEGORY,
  ZOHO_EDIT_API_CATEGORY,
  FINISH_CONFIGURATION_URL,
  API_URL,
} from '../config';
import Universal20Service, { getAccessToken } from './Universal20Service';

export default class ZohoFlowService {
  constructor() {}

  sendEditModeUpdateDataToZoho(questionController) {
    return new Promise((resolve, reject) => {
      try {
        const id = questionController.getId();
        let posturl = ZOHO_EDIT_API + id;
        if (questionController.isCategoryMode()) {
          posturl = ZOHO_EDIT_API_CATEGORY + id;
        }
        const summaryConfig = questionController.getSummaryConfig();
        const orderInfos = summaryConfig.orderInfos;
        orderInfos.categoryMode = questionController.getCurrentCategoryMode();

        return this.sendDataToZoho(
          questionController,
          orderInfos,
          posturl
        ).then(() => {
          resolve();
        });
      } catch (e) {
        return reject(e);
      }
    });
  }

  getAuthToken() {
    return getAccessToken();
  }

  sendDataToZohoOnlyCategory(questionController, orderInfos, posturl) {
    return new Promise((resolve, reject) => {
      try {
        const id = questionController.getId();
        const answers = questionController.getAllResults();
        const answerList = {};
        for (const a of answers.answers) {
          answerList[a.key] = a;
        }
        if (typeof posturl === 'undefined') {
          posturl = ZOHO_API_SAVE_CATEGORY + id;
        }

        const data = {
          answers: answerList,
          webconfigurator_templates: orderInfos.webconfigurator_templates,
          craftsmenTodos: answers.craftsmenTodos,
          agenturTodos: answers.agenturTodos,
          products: answers.products,
          categoryMode: orderInfos.categoryMode,
          orderInfos: orderInfos,
          Unternehmensgruppe: WEBCONFIG_TRADE_MODE,
          contract_order:
                  configuration.contractUrl + id,
          contract_av:
            configuration.avUrl + id,
          cancellation_url: WEBCONFIG_HOME_URL + 'widerruf/' + id,
          resultsPage: WEBCONFIG_HOME_URL + '#cid=' + id + '&page=answers',
          resultsPDF:
            configuration.resultsPDF + id,
        };
        return axios
          .post(posturl, data, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            // window.location.reload();

            return resolve();
          })
          .catch((err) => {
            return reject(err);
          });
      } catch (e) {
        return reject(e);
      }
    });
  }

  sendDataToZoho(questionController, orderInfos, posturl) {
    return new Promise((resolve, reject) => {
      try {
        return questionController
          .saveQuestionsComplete()
          .then(() => {
            const configuration = questionController.getConfiguration();
            const id = questionController.getId();
            const configurator_id = Universal20Service.getHostnameSubdomain();
            const answers = questionController.getAllResults();
            const answerList = {};
            for (const a of answers.answers) {
              answerList[a.key] = a;
            }
            if (typeof posturl === 'undefined') {
              posturl =
                API_URL +
                FINISH_CONFIGURATION_URL +
                '/' +
                configurator_id +
                '/' +
                id;
            }

            const data = {
              configuratorId: configurator_id,
              dataId: id,
              answers: answerList,
              craftsmenTodos: answers.craftsmenTodos,
              webconfigurator_templates: orderInfos.webconfigurator_templates,
              agenturTodos: answers.agenturTodos,
              products: answers.products,
              orderInfos: orderInfos,
              Unternehmensgruppe: configuration.tradeMode,
              contract_order: configuration.contractUrl + id,
              contract_av: configuration.avUrl + id,
              cancellation_url: configuration.cancelUrl + id,
              resultsPage:
                configuration.resultsUrl + '#cid=' + id + '&page=answers',
              resultsPDF: configuration.resultsPDF + id,
            };
            return axios
              .post(posturl, data, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + this.getAuthToken(),
                },
              })
              .then((response) => {
                window.progressing = false;
                window.location.reload();

                return resolve();
              })
              .catch((err) => {
                alert(
                  'Es ist ein Fehler aufgetreten beim Anlegen der Konfiguration.'
                );
                window.progressing = false;
                return reject(err);
              });
          })
          .catch((err) => {
            window.progressing = false;
            return reject(err);
          });
      } catch (e) {
        window.progressing = false;
        return reject(e);
      }
    });
  }
}
