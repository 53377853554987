import React from 'react';

const BuildInfo = ({ branch_id }) => {

  return <small style={{position: "fixed", right: 15,
    bottom: 0, zIndex: 9, pointerEvents: "none"}} dangerouslySetInnerHTML={{__html: branch_id}}></small>;
};

export default BuildInfo;


