import { DynamicMappingDataQuery } from "@agenturid/u20tools";

export class QuestionDataHelper {

  constructor(qctrl) {
    this.qctrl = qctrl;
  }


  getOptionParentFieldName() {
    return 'parentItem';
  }

  getChildOptionsForOption(option, alloptions) {
    const l = [];
    for (const o of alloptions) {
      if (o[this.getOptionParentFieldName()]) {
        const parentId = o[this.getOptionParentFieldName()]._id;
        if (
          option._id === parentId ||
          (option.ref_orig && option.ref_orig === parentId)
        ) {
          o.children = this.getChildOptionsForOption(o, alloptions);
          l.push(o);
        }
      }
    }
    return l;
  }

  getOptionsHierarchical(question, alloptions) {
    const rootOptions = alloptions.filter(
      (m) =>
        typeof m[this.getOptionParentFieldName()] === 'undefined' ||
        m[this.getOptionParentFieldName()] == null ||
        typeof m[this.getOptionParentFieldName()]._id === 'undefined'
    );
    for (const o of rootOptions) {
      o.children = this.getChildOptionsForOption(o, alloptions);
    }
    return rootOptions;
  }

  filterDynamicOptions(question, options, hierarchically=false) {
    let o = options;

    if (question.dynamicDataMapping) {
      if (
        Array.isArray(question.dynamicDataMapping) &&
        question.dynamicDataMapping.length > 0
      ) {
        const w = qctrl.getWebsite();
        const list = [];
        for (const d of question.dynamicDataMapping) {
          try {
            if (d.query) {
              const q = new DynamicMappingDataQuery(w);
              if (d.query.query) {
                let l = q.filterList(o, d.query.query, d.query.select.id);
                l = q.sortElements(l, d.query.sort);
                list.push(...l);
              }
              else {
                let l = d.query.sort ? q.sortElements(o, d.query.sort) : o;
                list.push(...l);
              }

            } else {
              list.push(...o);
            }
          } catch (e) {
            console.error(e);
          }
        }
        o = list;
      }
    }

    if (hierarchically) {
      o = this.getOptionsHierarchical(question, o);
    }

    return o;
  }

  loadOptionsFromQuestion(question, hierarchically=false) {
    const options = question['options'];

    let o = options.filter((m) => {
      if (m.id) {
        return m.id != null;
      }
      if (m._id) {
        return m._id != null;
      }

      return true;
    });

    o = this.filterDynamicOptions(question, o, hierarchically);

    return o;
  }

}