import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "reactstrap/lib/Button";
import ZohoFlowService from "../../services/ZohoFlowService";
import LoadingOverlay from "react-loading-overlay";

export default class EditModeHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      waiting: false
    };

  }


  qCtrl() {
    return this.props.qctrl;
  }

  updateConfiguration() {

    this.zoho = new ZohoFlowService();
    if (confirm("Achtung wir speichern jetzt deine Konfiguration - du musst aber danach in Universal nochmal aktualisieren.")) {
      this.setState({ waiting: true });
      this.zoho.sendEditModeUpdateDataToZoho(this.qCtrl())
        .then(() => {
          this.setState({waiting: false});
          alert("Die Seite wurde aktualisiert - gehe nun bitte in Universal und lade die Konfiguration ein.");
        });
    }

  }

  render() {
    const waitingContainer = this.state.waiting && <LoadingOverlay
      active={this.state.waiting }
      spinner
      className={"overload-spinner"}
      text='Aktualisiere Einstellungen...'
    >
      <div className={"fullscreen-loader"}><br/></div>
    </LoadingOverlay>;

    if (this.state.waiting) {
      return waitingContainer;
    }

    const id = this.qCtrl().getId();
    return <div className={"edit-mode-header"}>Achtung - du bearbeitest eine fertige Konfiguration - pr&uuml;fe bitte genau was du tust! Wenn du den speichern button drückst wird wieder geschlossen. &nbsp;</div>;
  }


}
