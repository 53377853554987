import axios from "axios";
import { DB_API, DB_URL } from "../config";
import { getAccessToken } from "./Universal20Service";

export default class DBService {

    constructor(cid, formularId) {
        this.cid = cid;
        this.formularId = formularId;
    }

    setCid(cid) {
        this.cid = cid;
    }

    getCid() {
        return this.cid;
    }

    getFormularID() {
        return this.formularId;
    }

    getParameterDetails(parametrs) {
        return axios.post(DB_URL+DB_API+"parameterDetails/"+this.getFormularID()+"/"+this.getCid(),
          {parameters: parametrs}, {
              headers: {
                  "Authorization": "Bearer "+this.getAuthToken()
              }
          });
    }

    getPageDataByPath(path) {
        return axios.post(DB_URL+DB_API+"pageContent/"+this.getFormularID()+"/"+this.getCid(),
          {path: path}, {
              headers: {
                  "Authorization": "Bearer "+this.getAuthToken()
              }
          });
    }

    saveState(states, fixed_values, comments, state) {
        return axios.put(DB_URL+DB_API+"saveState/"+this.getFormularID()+"/"+this.getCid(),
          {answers: states, fixed_values: fixed_values, comments: comments, state: state}, {
            headers: {
                "Authorization": "Bearer "+this.getAuthToken()
            }
        });
    }

    getAuthToken() {
        return getAccessToken();
    }

    loadState() {
        return Promise.resolve();
    }

}
