import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck, FaCheckCircle, FaCrosshairs, FaWindowClose } from "react-icons/fa";
import { FaCross } from "react-icons/all";

class QuestionRegex extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.getRealData();
    }

    getTextValue() {
        let txt = this.getValue();
        if (txt == null && this.hasVorauswahl()) {
            txt = this.getVorauswahl();
            this.setValue(txt);
        }
        if (txt === null) {
            txt = "";
        }
        return txt;
    }

    placeholder() {
        if (this.question().placeholder) {
            return this.question().regexText.replace(/\\/g, '');
        }
        return null;
    }

    renderRegexInfos() {
        const matches = this.getTextValue();

        if (!this.getTextValue()) {
            return null;
        }

        let length = 0;
        for (const m of matches) {
            if (m.length <= 0) {
                return <div style={{color: "red", fontSize: 16, paddingTop: 6}}>
                    <FaWindowClose size={12} color={"red"} /> Einige der Inhalte sind zu kurz
                </div>;
            }
            else {
                length++;
            }
        }
        if (length < this.question().commands.length) {
            return <div style={{color: "red", fontSize: 16, paddingTop: 6}}>
                <FaWindowClose size={12} color={"red"} /> Es fehlen einige Inhalte
            </div>;
        }

        return <div style={{color: "green", fontSize: 16, paddingTop: 6}}>
            <FaCheckCircle size={14} color={"green"} /> Korrekte Eingabe
        </div>;
    }

    getReplacedTextValue() {
        let txt = this.getTextValue();
        if (txt == null || txt.length <= 0) {
            return null;
        }
        let replaced_text = this.question().regexText.replace(/\\/g, '');
        if (Array.isArray(txt)) {
            const matches_of_matches = replaced_text.match(new RegExp("(?<=)\\(.+?(?=\\))\\)", "g"));
            let i=0;
            for (const e of matches_of_matches) {
                replaced_text = replaced_text.replace(e, txt[i++]);
            }
        }

        return replaced_text;
    }

    setRegexData(value) {
        const matches = value.matchAll(this.question().regexText);
        let data = [];
        for (const m of matches) {
            let i = 0;
            for (const d of m) {
                if (i > 0) {
                    data.push(d);
                }
                i++;
            }
           break;
        }
        this.setValueQuick(data, null, 1000);
        setTimeout(()=> this.setRealData(data), 400);
    }

    getRealData() {
        let i = 0;
        if (this.question().commands) {
            const data = [];
            for (const c of this.question().commands) {
                if ( c.field) {
                    const d = this.qCtrl().getValueForField(c.field);
                    if (d) {
                        data.push(d);
                    }
                }
                i++;
            }
            if (data.length == this.question().commands.length) {
                this.setValueQuick(data, null, 1000);
            }
        }
    }

    setRealData(data) {
        let i = 0;
        for (const c of this.question().commands) {
            if (data[i] && c.field) {
                this.setFixedValue(this.getId()+"_"+i, c.field.id, data[i]);
            }
            i++;
        }
    }

    render() {
        return <Container fluid={true} className={"text-container "+(this.hasError() ? "error" : "")}>
            <Row>
                <Col className={"p-1"} md={12}>
                    {this.isVorauswahl() && <FaCheck className={"vorauswahl_check"} onClick={(e) => this.onVorauswahlCheckClicked(e)}/>}
                    <Input type={"textarea"} rows={4} value={this.getReplacedTextValue()}
                           disabled={this.isFinished() || this.isDisabled()}
                           onChange={(e) => this.setRegexData(e.target.value)}
                           name={"text"}
                           placeholder={this.placeholder()} />
                    {this.renderErrors()}
                    {this.renderRegexInfos()}
                </Col>
            </Row>
        </Container>;
    }
};

QuestionRegex.propTypes = {

};


export default QuestionRegex;
