import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';
import Rating from "react-rating";
import { AiFillInfoCircle } from "react-icons/all";
import Handlebars from "handlebars";



class QuestionHelptext extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getValueAsInt() {
        try {
            return parseInt(this.getValue());
        }
        catch(e) {
            return 0;
        }
    }

    getVorauswahlasInt() {
        try {
            return parseInt(this.getVorauswahl());
        }
        catch(e) {
            return 0;
        }
    }

    componentDidMount() {

    }

    getParsedHtml() {
        const data = this.question()["htmltext"];
        try {
            var template = Handlebars.compile(data ? data : '');
            const d = template(Object.assign({}, this.qCtrl().formular.state.website,{
                zoho: this.qCtrl().getZohoCustomerData(),

            }));
            return d;
        }
        catch(e) {

        }
        return data;
    }


    render() {
        return <Container fluid={false} className={" helptext-container "+(this.hasError() ? "error" : "")}>
            <Row>
                <Col className={"p-1"} md={12}>
                    <AiFillInfoCircle size={20} style={{marginRight: "6px", marginBottom: "3px"
                        , fill: "#007bff"}} />
                    <span dangerouslySetInnerHTML={{ __html: this.getParsedHtml() }}></span>
                </Col>
            </Row>
        </Container>;
    }
};

QuestionHelptext.propTypes = {

};


export default QuestionHelptext;
