import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class FinishedHeader extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    return <div className={"finished-header"}>Diese Konfiguration ist bereits fertig und kann nicht verändert werden.</div>;
  }


}
